import { Suspense, useContext } from 'react';
import PT from 'prop-types';
import { Grid2, Skeleton } from '@mui/material';
import { ExpandLess, ExpandMore, Link as LinkIcon } from '@mui/icons-material';
import { StyledIconButton } from 'components/buttons';
import { FavouritesButton } from 'components/favourites';
import { isValidUrl } from 'utils';
import { UserContext } from 'context';

function ProjectCardActions({ loading, project, expanded, setExpanded }) {
  const { user } = useContext(UserContext);

  return (
    <Grid2
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: '98%' }}
    >
      <Grid2 item size={10}>
        <Suspense fallback={null}>
          <FavouritesButton loading={loading} project={project} />
          {project.projectUrl &&
            isValidUrl(project.projectUrl) &&
            user?.roles?.includes('App.Internal') && (
              <StyledIconButton
                title={`${'Link to Arup project page'}: ${project.shortTitle}`}
                disabled={loading}
                onClick={event => {
                  event.preventDefault();
                  window.open(project.projectUrl);
                }}
                icon={
                  loading ? (
                    <Skeleton width={20} height={20} data-testid="loading" />
                  ) : (
                    <LinkIcon />
                  )
                }
              />
            )}
        </Suspense>
      </Grid2>
      <Grid2 item size={2} alignContent="right">
        <Suspense fallback={null}>
          <StyledIconButton
            disabled={loading}
            title={
              expanded?.includes(project.projectId) ? 'Show less' : 'Show more'
            }
            onClick={() => {
              if (expanded?.includes(project.projectId)) {
                setExpanded(curr => {
                  const index = curr.indexOf(project.projectId);
                  curr.splice(index, 1);
                  return [...curr];
                });
              } else {
                setExpanded(curr => [...curr, project.projectId]);
              }
            }}
            icon={
              // eslint-disable-next-line no-nested-ternary
              loading ? (
                <Skeleton width={20} height={20} data-testid="loading" />
              ) : expanded?.includes(project.projectId) ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            }
          />
        </Suspense>
      </Grid2>
    </Grid2>
  );
}

ProjectCardActions.propTypes = {
  project: PT.shape({
    shortTitle: PT.string,
    projectUrl: PT.string,
    number: PT.string,
    projectId: PT.string,
    projectDirectorName: PT.string,
    centreCode: PT.string,
    projectManagerEmail: PT.string,
    scopeOfWorks: PT.string,
    confidential: PT.bool,
  }),
  loading: PT.bool.isRequired,
  expanded: PT.arrayOf(PT.string),
  setExpanded: PT.func,
};

ProjectCardActions.defaultProps = {
  project: {
    shortTitle: '',
    projectUrl: '',
    number: null,
    projectId: '',
    projectDirectorName: '',
    centreCode: '',
    projectManagerEmail: '',
    scopeOfWorks: '',
    confidential: null,
  },
  expanded: [],
  setExpanded: () => {},
};

export default ProjectCardActions;

/* eslint-disable react/forbid-prop-types */
import PT from 'prop-types';
import { useContext } from 'react';
import { Typography, Box, useMediaQuery } from '@mui/material';
import { ThemeContext } from 'context';
import { StyledTreeItem, StyledBox } from './tree-item-styles';

const TreeItem = props => {
  const {
    textWeight,
    color,
    labelIcon,
    labelInfo,
    labelText,
    onLabelClick,
    setMoreVertVisible,
    ...other
  } = props;
  const { localTheme } = useContext(ThemeContext);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <StyledTreeItem
      mode={localTheme}
      aria-label="tree-item"
      data-cy="tree-item"
      label={
        <StyledBox
          onClick={onLabelClick}
          onMouseEnter={() => setMoreVertVisible(true)}
          onMouseLeave={() => setMoreVertVisible(false)}
        >
          {labelIcon}
          {
            <Box
              sx={{
                maxWidth: isSmallScreen
                  ? 'calc(100% - 65px)'
                  : 'calc(100% - 130px)',
              }}
            >
              <Typography
                data-cy="tree-item-label"
                variant="body2"
                color={color || 'primary'}
                component="div"
                sx={{
                  fontWeight: textWeight,
                  flexGrow: 0,
                  flexWrap: 'none',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {labelText}
              </Typography>
            </Box>
          }
          {labelInfo}
        </StyledBox>
      }
      {...other}
    />
  );
};

TreeItem.propTypes = {
  textWeight: PT.number,
  color: PT.oneOfType([PT.string, PT.func]),
  labelIcon: PT.any,
  labelInfo: PT.oneOfType([PT.string, PT.func]),
  labelText: PT.oneOfType([PT.string, PT.element]).isRequired,
  onLabelClick: PT.func,
  node: PT.oneOfType([
    PT.shape({ edited: PT.bool, id: PT.string, new: PT.bool }),
    PT.func,
  ]),
  isTemplateDetails: PT.bool,
  setMoreVertVisible: PT.func,
};

TreeItem.defaultProps = {
  textWeight: 400,
  color: 'primary',
  labelIcon: null,
  labelInfo: '',
  onLabelClick: () => {},
  node: {},
  isTemplateDetails: false,
  setMoreVertVisible: () => {},
};

export default TreeItem;

import { Suspense } from 'react';
import { useMatch } from 'react-router';
import { useMediaQuery, AppBar, Toolbar, Avatar, Box } from '@mui/material';
import HomeButton from './home';
import User from './user';
import { MobileMenu, MainMenu } from './menu';
import Docs from './docs';

function HeaderToolbar() {
  const screenSize = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const matchDocs = useMatch('/docs/*');
  const matchIntegrations = useMatch('/integrations/*');

  return (
    <>
      <AppBar
        sx={theme => ({
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.background.paper,
          boxShadow: 'none',
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          ...theme.applyStyles('dark', {
            borderBottom: `1px solid ${theme.palette.grey[700]}`,
          }),
        })}
      >
        <Toolbar>
          <Suspense fallback={null}>
            <HomeButton data-cy="homeButton" />
            {(matchDocs || matchIntegrations) && (
              <Docs
                match={
                  matchDocs?.pathnameBase || matchIntegrations?.pathnameBase
                }
              />
            )}
          </Suspense>
          <Box sx={{ flexGrow: 1 }} />
          {screenSize ? (
            <Suspense fallback={null}>
              <MainMenu />
            </Suspense>
          ) : (
            <Suspense fallback={null}>
              <MobileMenu />
            </Suspense>
          )}
          <Suspense fallback={<Avatar />}>
            <User />
          </Suspense>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
    </>
  );
}

export default HeaderToolbar;

import { Grid2 } from '@mui/material';
import { styled } from '@mui/material/styles';
import PT from 'prop-types';
import { Suspense } from 'react';
import StyledCollapse from './folding-drawer-styles';
import FoldingHeader from './header';

const Container = styled(Grid2)(({ theme }) => ({
  height: `calc(100vh - 64px)`,
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - 58px)`,
  },
}));

const FoldingDrawer = ({
  open,
  onClick,
  children,
  tooltip,
  width,
  disabled,
  collapsedTooltip,
}) => {
  return (
    <Container
      aria-label="folding-drawer"
      item
      sx={{
        flexGrow: open ? 1 : 0,
        width: width && open ? width : 'auto',
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <Suspense fallback={null}>
        <FoldingHeader
          title={tooltip}
          collapsedTooltip={collapsedTooltip}
          onClick={onClick}
          open={open}
          disabled={disabled}
        />
      </Suspense>
      <StyledCollapse orientation="horizontal" in={open} timeout="auto">
        {children}
      </StyledCollapse>
    </Container>
  );
};

FoldingDrawer.propTypes = {
  open: PT.bool.isRequired,
  onClick: PT.func.isRequired,
  children: PT.node.isRequired,
  tooltip: PT.string,
  collapsedTooltip: PT.string,
  width: PT.string,
  disabled: PT.bool,
};

FoldingDrawer.defaultProps = {
  width: 'auto',
  disabled: false,
  tooltip: null,
  collapsedTooltip: null,
};
export default FoldingDrawer;

import { Inventory } from '@mui/icons-material';
import { Typography } from '@mui/material';
import DataDrawerGrid from '../../../parameters-page/common/grid';
import AssetTypesSummary from './summary/summary';

function AssetTypes() {
  return (
    <DataDrawerGrid
      icon={
        <Inventory
          data-testid="asset-types-icon"
          sx={theme => ({
            color: theme.palette.primary.black,
          })}
        />
      }
      caption={
        <Typography
          variant="caption"
          color="textPrimary"
          data-testid="asset-types-label"
        >
          Asset types
        </Typography>
      }
      content={<AssetTypesSummary />}
    />
  );
}

export default AssetTypes;

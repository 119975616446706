import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Instructions = () => {
  const steps = [
    {
      primary: 'Instructions',
      secondary:
        'A template is a way to quickly apply a group of parameters or assets (a data set) to your project. This can help you populate projects faster, and to get all requirements ready to generate reports. A data set is a template that has been applied to a project using specific assets in that project. You can have multiple data sets for each template.',
    },
    {
      primary: 'Select Assets',
      secondary:
        'For the template to work you need to select all the assets in its “recipe”. Use the template descriptions to choose the right assets for the data set (example: match a “Cold water storage tank” with an asset that fulfils that role).',
    },
    {
      primary: 'Confirm parameters to add',
      secondary:
        'Some of the parameters might be already in use by other data sets in your project.',
    },
    {
      primary: 'Create data set',
      secondary:
        'If you leave the process before this step the data set will not be saved.',
    },
  ];

  const Circle = styled('div')(({ theme }) => ({
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
  }));

  return (
    <Box aria-label="data-set-wizard-instructions" sx={{ width: '100%' }}>
      <Typography aria-label="create-data-set-steps" variant="body1">
        To create a data set follow these steps:
      </Typography>
      <List aria-label="instructions-list">
        {steps.map((step, index) => (
          <div key={step.primary}>
            <ListItem aria-label="instruction-list-item">
              <ListItemIcon aria-label="list-item-icon">
                <Circle>{index + 1}</Circle>
              </ListItemIcon>
              <ListItemText primary={step.primary} secondary={step.secondary} />
            </ListItem>
            {index < steps.length - 1 && <Divider />}
          </div>
        ))}
      </List>
    </Box>
  );
};
export default Instructions;

import PT from 'prop-types';
import {
  Grid2,
  Breadcrumbs,
  useMediaQuery,
  Chip,
  Skeleton,
} from '@mui/material';
import { NavigateNext } from '@mui/icons-material';

function HeaderBreadcrumbs(props) {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const screenXs = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const { assetsLoading, assetList, wizard } = props;

  return (
    <Grid2 item size={{ xs: 6, sm: 12, md: 12 }}>
      <Breadcrumbs
        role="presentation"
        // eslint-disable-next-line no-nested-ternary
        maxItems={wizard ? 6 : screenXs || screenSm ? 2 : 3}
        separator={<NavigateNext fontSize="small" color="inherit" />}
      >
        {assetsLoading && (
          <Skeleton
            sx={{ borderRadius: '16px!important' }}
            height={32}
            width={100}
          />
        )}
        {assetList?.length &&
          assetList?.map(({ name, id }) => (
            <Chip key={id} size="small" label={name} />
          ))}
      </Breadcrumbs>
    </Grid2>
  );
}

HeaderBreadcrumbs.propTypes = {
  assetsLoading: PT.bool.isRequired,
  assetList: PT.arrayOf(PT.object.isRequired).isRequired,
  wizard: PT.bool,
};
HeaderBreadcrumbs.defaultProps = { wizard: false };

export default HeaderBreadcrumbs;

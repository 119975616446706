/* eslint-disable no-nested-ternary */

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Tooltip,
  ListItemButton,
} from '@mui/material';
import PT from 'prop-types';
import {
  Add,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useSearchParams } from 'react-router';
import qs from 'qs';
import { AssetContext } from 'context';
import { StyledIconButton } from 'components/buttons';
import useTypeItem from './hooks/use-type-item';

const ItemType = ({ type, open, columnIndex }) => {
  const { loadAssetSubTypes, assetsLoading } = useTypeItem(type);
  const { assetManipulation, setAssetManipulation } = useContext(AssetContext);
  const [searchParams] = useSearchParams();
  const { ...parsedSearch } = qs.parse(searchParams.toString());
  const showDeletedAssets = parsedSearch.show_deleted_assets === 'true';
  const getChildrenLength = () => {
    if (showDeletedAssets) return type.children.length;
    return type.children.filter(child => !child.deletedAt).length;
  };
  const typeId = type?.parentAssetSubTypeId?.id || type.id;

  return (
    <ListItem
      aria-label="item-type"
      key={type.name}
      data-cy={`wizard-column-item-${columnIndex}`}
      onClick={() => {
        setAssetManipulation(curr => {
          curr.isOpen[type.name] = !open;
        });
      }}
      sx={theme => ({
        padding: theme.spacing(0),
        '&$disabled': {
          opacity: 0.5,
        },
      })}
      secondaryAction={
        assetsLoading ? (
          <CircularProgress size={24} aria-label="loading-icon" />
        ) : (
          <StyledIconButton
            aria-label="add-icon"
            onClick={() => {
              setAssetManipulation(curr => {
                curr.hasSubTypes[typeId] = [];
              });
              loadAssetSubTypes();
            }}
            title={
              assetManipulation.allSubTypesUsed[typeId] === true
                ? 'No available Sub Types'
                : type.parentDeleted
                  ? 'Cannot add assets to deleted parent.'
                  : 'Add new assets'
            }
            disabled={
              type.parentDeleted ||
              assetManipulation.isAddingNew[type.name] ||
              assetManipulation.allSubTypesUsed[typeId]
            }
            data-cy={`wizard-column-item-add-${columnIndex}`}
            data-testid={`wizard-column-item-add-${columnIndex}`}
            icon={<Add data-cy={`wizard-addItem-${columnIndex}`} />}
          />
        )
      }
    >
      <ListItemButton
        disabled={
          isEmpty(type.children) ||
          (!showDeletedAssets &&
            type.children.every(child => child.deletedAt)) ||
          false
        }
      >
        <ListItemIcon
          sx={{ maxWidth: 24, minWidth: 24 }}
          data-cy={`wizard-column-item-icon-${columnIndex}`}
        >
          {open ? (
            <Tooltip title="Show less">
              <KeyboardArrowDown aria-label="collapse-icon" />
            </Tooltip>
          ) : (
            <Tooltip title="Show more">
              <KeyboardArrowRight aria-label="expand-icon" />
            </Tooltip>
          )}
        </ListItemIcon>
        <ListItemText
          aria-label="item-type-title"
          sx={{
            textTransform: 'capitalize',
            fontWeight: 'bold',
            fontSize: '14px',
          }}
          disableTypography
        >
          {`${type.name} (${getChildrenLength()})`}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

ItemType.propTypes = {
  type: PT.shape({
    name: PT.string,
    id: PT.string,
    children: PT.arrayOf(PT.shape({})),
    isSubType: PT.bool,
    parentAssetSubTypeId: PT.string || null || undefined,
    parentDeleted: PT.string || null || undefined,
  }).isRequired,
  open: PT.bool,
  columnIndex: PT.number.isRequired,
};

ItemType.defaultProps = {
  open: false,
};
export default ItemType;

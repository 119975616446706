import { Typography, Grid2, Skeleton } from '@mui/material';
import PT from 'prop-types';
import { parameterType } from 'types';
import Alert from 'components/alerts';
import useRejectedAlert from './hooks/use-rejected-alert';

function RejectedAlert({ parameter, setOpenRejectedAlert }) {
  const { rejectedAlert, isLoading } = useRejectedAlert(
    parameter,
    setOpenRejectedAlert
  );

  if (isLoading) {
    return (
      <Alert
        title={
          <Skeleton
            aria-label="loading"
            height={35}
            width={300}
            display="inline"
          />
        }
        text={<Skeleton height={35} width={80} display="inline" />}
      />
    );
  }
  return (
    <Alert
      title={
        <Typography variant="subtitle1" aria-label="title">
          Reason Rejected: {rejectedAlert.comment}
        </Typography>
      }
      text={
        <Grid2 container direction="column">
          <Typography variant="caption" aria-label="rejected-by">
            Rejected by: {rejectedAlert.name}
          </Typography>
          <Typography variant="caption" aria-label="rejected-on">
            Rejected on: {rejectedAlert.date}
          </Typography>
        </Grid2>
      }
    />
  );
}

RejectedAlert.propTypes = {
  parameter: parameterType.isRequired,
  setOpenRejectedAlert: PT.func.isRequired,
};
export default RejectedAlert;

import PT from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid2,
} from '@mui/material';
import { Close as CloseIcon, Send } from '@mui/icons-material';
import { StyledIconButton } from '../../buttons';

function WarningDialog(props) {
  const {
    open,
    handleClose,
    handleAction,
    title,
    body,
    primaryButtonText,
    secondaryButtonText,
    secondaryButton,
    itemOptions,
    icon,
    disableClose,
  } = props;

  const availableOptions = itemOptions?.props?.valueOptions;

  return (
    <Dialog
      data-testid="warning-dialog"
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid2
        container
        overflow="none"
        justifyContent="space-between"
        flexDirection="row"
        pt={theme => theme.spacing(0.5)}
        pr={theme => theme.spacing(0.5)}
      >
        <Grid2 item size={11}>
          <DialogTitle sx={{ wordWrap: 'break-word' }} id="alert-dialog-title">
            {title}
            {availableOptions && icon}
          </DialogTitle>
        </Grid2>
        {!disableClose && (
          <Grid2 item size={1}>
            <StyledIconButton
              data-cy="warning-close-button"
              type="warning-close-button"
              aria-label="close"
              title={secondaryButtonText}
              icon={<CloseIcon />}
              onClick={handleClose}
            />
          </Grid2>
        )}
      </Grid2>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
        {itemOptions}
      </DialogContent>
      <DialogActions>
        {secondaryButton && (
          <Button
            data-cy="warning-second-button"
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            {secondaryButtonText}
          </Button>
        )}
        <Button
          data-cy={
            availableOptions
              ? 'warning-request-button'
              : 'warning-accept-button'
          }
          onClick={handleAction}
          color="primary"
          variant="outlined"
          endIcon={availableOptions && <Send />}
        >
          {primaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

WarningDialog.propTypes = {
  open: PT.bool,
  handleAction: PT.func,
  handleClose: PT.func,
  title: PT.string,
  body: PT.string,
  primaryButtonText: PT.string,
  secondaryButtonText: PT.string,
  secondaryButton: PT.bool,
  itemOptions: PT.node,
  icon: PT.node,
  disableClose: PT.bool,
};

WarningDialog.defaultProps = {
  open: false,
  title: '',
  body: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  handleAction: () => {},
  handleClose: () => {},
  secondaryButton: false,
  itemOptions: '',
  icon: '',
  disableClose: false,
};

export default WarningDialog;

import { useContext } from 'react';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { Box, Grid2, Typography, Tooltip } from '@mui/material';
import { useCookies } from 'react-cookie';
import { withAppInsights } from 'app/app-insights';
import { ThemeContext } from 'context';
import { StyledSwitch } from 'components/styles/switch-styles';

function UserTheme() {
  const { handleThemeChange, paletteType, localTheme } =
    useContext(ThemeContext);
  const [cookies] = useCookies(['ddbCookiesPolicyAccepted']);
  return (
    <Box
      sx={theme => ({
        padding: theme.spacing(5),
      })}
      data-testid="user-settings-theme"
    >
      <Typography variant="h4" component="h1" mb={3} gutterBottom>
        Theme
      </Typography>
      <Typography variant="subtitle1" component="p" gutterBottom>
        Toggle dark/light theme
      </Typography>
      <Grid2 component="label" container spacing={1} alignItems="center">
        <Grid2 item>
          <Brightness4 />
        </Grid2>
        <Grid2 item size={{ mb: theme => theme.spacing(0.5) }}>
          <Tooltip
            title={
              !cookies?.ddbCookiesPolicyAccepted?.functional
                ? `'Theme - ${localTheme} - DDB will not remember your Theme after page refresh if functionality cookies are not accepted. Visit the User Settings page to update cookies preferences.`
                : `${'Theme'} - ${localTheme}`
            }
          >
            <span>
              <StyledSwitch
                palette={localTheme}
                aria-label="theme-toggle"
                checked={paletteType}
                onChange={handleThemeChange}
                name="theme"
                color="primary"
                data-cy="theme-toggle"
              />
            </span>
          </Tooltip>
        </Grid2>
        <Grid2 item>
          <Brightness7 />
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default withAppInsights(UserTheme, 'UserThemePage');

import PT from 'prop-types';
import { Typography, Box, Collapse } from '@mui/material';
import ExpandOrCollapseButton from 'components/buttons/icons/expand-or-collapse';

function DataTemplateDetails({ open, handleOpen, content, subtitle }) {
  return (
    <Box aria-label="contentBox" width="100%">
      <ExpandOrCollapseButton
        open={open}
        onClick={handleOpen}
        sx={theme => ({
          marginLeft: theme.spacing(-1.5),
        })}
      />
      <Typography variant="caption" display="inline" color="textSecondary">
        {subtitle}
      </Typography>
      <Collapse in={open}>
        <Typography
          data-testid="value"
          sx={theme => ({
            color: theme.palette.secondary.dark,
          })}
          color="textPrimary"
        />
        {content}
      </Collapse>
    </Box>
  );
}

DataTemplateDetails.propTypes = {
  open: PT.bool,
  handleOpen: PT.func,
  content: PT.oneOfType([PT.string, PT.node, PT.arrayOf(PT.node)]),
  subtitle: PT.string,
};
DataTemplateDetails.defaultProps = {
  open: false,
  handleOpen: () => {},
  content: '',
  subtitle: '',
};

export default DataTemplateDetails;

import { styled } from '@mui/material/styles';
import { Container, Grid2, Typography, Divider } from '@mui/material';

export const StyledContainer = styled(Container)(({ theme }) => ({
  color: theme.palette.mode !== 'dark' && theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    height: '65vh',
  },
}));

export const StyledGrid = styled(Grid2)(({ theme }) => ({
  height: '100%',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(10),
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  letterSpacing: '.7rem',
  fontWeight: theme.typography.fontWeightLight,
  [theme.breakpoints.only('xs')]: {
    fontSize: 24,
  },
}));

export const StyledStraplineTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  letterSpacing: '.1rem',
  fontWeight: theme.typography.fontWeightLight,
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.only('xs')]: {
    paddingTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));

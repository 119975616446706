import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid2,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Link,
  Skeleton,
  Box,
} from '@mui/material';
import { Email } from '@mui/icons-material';
import html from 'remark-html';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import rehypeHighlight from 'rehype-highlight';
import powershell from 'highlight.js/lib/languages/powershell';
import { isEmpty } from 'lodash';
import { StyledIconButton } from 'components/buttons';
import { legal } from 'components/footer/links';
import useGetCookies from 'routes/user-settings/cookies/hooks/use-get-cookies';
import Alert from 'components/alerts';
import StyledMarkdown from './markdown-styles';

function TermsConditionsConsent() {
  const {
    openTermsConditions,
    onAcceptPolicyClick,
    policyLastUpdated,
    agreeChecked,
    setAgreeChecked,
    apiDocs,
    markdownLoading,
    additionalLinks,
    imageString,
    error,
  } = useGetCookies();

  const linksList = [...legal];
  linksList.splice(3, 0, ...additionalLinks);

  let dateString;
  if (policyLastUpdated) {
    dateString = policyLastUpdated.toDateString();
  }

  return (
    <Dialog
      maxWidth="md"
      open={openTermsConditions}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        component="span"
        id="scroll-dialog-title"
        sx={theme => ({
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(1),
        })}
      >
        <Typography variant="h4">DDB Acceptable Use Policy (AUP)</Typography>
        {markdownLoading && (
          <Skeleton
            height={30}
            width="100%"
            display="inline"
            aria-label="loading-date"
          />
        )}
        {!markdownLoading && !isEmpty(error) && (
          <Box
            sx={theme => ({
              padding: theme.spacing(3),
            })}
            aria-label="markdown-error"
          >
            <Alert title={error.message} text={error.response.status} />
          </Box>
        )}
        {!markdownLoading && isEmpty(error) && (
          <>
            <Typography variant="h">{`Last updated: ${dateString}`}</Typography>
            <Typography
              variant="body2"
              sx={theme => ({
                paddingTop: theme.spacing(2),
              })}
            >
              You must accept the AUP to use Digital Design Brief.
            </Typography>
          </>
        )}
      </DialogTitle>
      <DialogContent
        dividers
        sx={theme => ({
          padding: theme.spacing(1),
          minHeight: theme.spacing(40),
        })}
      >
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={0}
          component="span"
        >
          {markdownLoading || !isEmpty(error) ? (
            <Skeleton
              height={30}
              width="100%"
              display="inline"
              aria-label="loading-markdown"
            />
          ) : (
            <>
              <StyledMarkdown
                remarkPlugins={[html]}
                rehypePlugins={[
                  [rehypeHighlight, { languages: { powershell } }],
                  rehypeRaw,
                  rehypeSlug,
                ]}
              >
                {apiDocs[0]}
              </StyledMarkdown>
              <Grid2
                aria-label="downloadImage"
                sx={theme => ({
                  paddingLeft: theme.spacing(10),
                })}
              >
                <a
                  download
                  href={`data:image/svg+xml;utf8,${encodeURIComponent(
                    imageString
                  )}`}
                  alt="Powered By DDB"
                >
                  <img
                    alt="Powered By DDB"
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      imageString
                    )}`}
                  />
                </a>
              </Grid2>
              <StyledMarkdown
                remarkPlugins={[html]}
                rehypePlugins={[
                  [rehypeHighlight, { languages: { powershell } }],
                  rehypeRaw,
                  rehypeSlug,
                ]}
              >
                {apiDocs[1]}
              </StyledMarkdown>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={theme => ({
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(1),
          paddingTop: theme.spacing(2),
        })}
      >
        <Grid2 container width="100%">
          <Grid2 item>
            <Typography variant="h6">Supporting Documents</Typography>
            <Typography variant="body2" gutterBottom>
              The following list provides references to Supporting Documents for
              this AUP. It is recommended that you review the content of the
              Supporting Documents prior to using the Service:
            </Typography>
            <Grid2 container>
              <ul
                style={{
                  listStyle: 'none',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                }}
              >
                {linksList?.map(({ text, name, ...rest }) => (
                  <li key={text}>
                    <Link
                      sx={theme => ({
                        paddingRight: theme.spacing(3),
                      })}
                      underline="always"
                      color="primary"
                      variant="body2"
                      aria-label={name}
                      data-cy={name}
                      {...rest}
                    >
                      {text.includes('arup')
                        ? text
                        : `Digital Design Brief - ${text}`}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid2>
          </Grid2>
          <Grid2
            item
            size={12}
            sx={theme => ({
              marginTop: theme.spacing(1),
            })}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isEmpty(error) || markdownLoading}
                    checked={agreeChecked}
                    onChange={() => {
                      setAgreeChecked(!agreeChecked);
                    }}
                  />
                }
                label="I agree with the Acceptable Use Policy"
              />
            </FormGroup>
          </Grid2>
          <Grid2
            display="flex"
            justifyContent="center"
            item
            size={12}
            sx={theme => ({
              marginTop: theme.spacing(1),
            })}
          >
            <Button
              sx={{ width: '60%', padding: 'auto' }}
              disabled={!agreeChecked}
              aria-label="accept-terms-button"
              variant="contained"
              onClick={onAcceptPolicyClick}
            >
              Accept
            </Button>
          </Grid2>
          <Grid2
            display="flex"
            justifyContent="flex-end"
            alignItems="baseline"
            item
            size={12}
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            <Typography>Need help? Contact us</Typography>
            <StyledIconButton
              type="contactButton"
              title="Contact Us"
              variant="outlined"
              onClick={() => {
                window.open(
                  'mailto:ddb@arup.com?subject=DDB Terms and Conditions'
                );
              }}
              icon={<Email />}
            />
          </Grid2>
        </Grid2>
      </DialogActions>
    </Dialog>
  );
}

export default TermsConditionsConsent;

import { Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ContactOnboarding = ({ isMdUp }) => {
  return (
    <Typography
      variant={isMdUp ? 'subtitle1' : 'h6'}
      component="h2"
      fontWeight={theme => theme.typography.fontWeightRegular}
      sx={theme => ({
        paddingBottom: theme.spacing(8),
      })}
    >
      {
        'Interested in using DDB for your project? Try it out for yourself on our '
      }
      <Link target="_blank" href="https://sandbox.ddb.arup.com/">
        Sandbox site
      </Link>{' '}
      {'or, '}
      <Link
        target="_blank"
        href="https://forms.office.com/Pages/ResponsePage.aspx?id=QYvkSjcBmUWGYfxkH-d76jWhSs5jgN9IqtOZf5LAmspUODU2M1FUQTdBM0lDNjlQNkg0VE5OUVJGQyQlQCN0PWcu"
      >
        Contact our DDB onboarding team
      </Link>{' '}
      {'for support!'}
    </Typography>
  );
};

ContactOnboarding.propTypes = {
  isMdUp: PropTypes.bool.isRequired,
};

export default ContactOnboarding;

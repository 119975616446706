/* eslint-disable react/prop-types */

import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem, Tooltip, ClickAwayListener } from '@mui/material';
import useTreeItemMenu from './hooks/use-tree-item-menu';

const TreeItemMenu = ({
  handleDeleteClick,
  handleRestoreClick,
  setMenuOpen,
  moreVertVisible,
  setMoreVertVisible,
  setIsDuplicateAssetOpen,
  setOpenEditingForm,
  menuOpen,
  node,
  projectLevelButton,
}) => {
  const {
    anchorEl,
    menuItems,
    handleMenuOpen,
    handleMenuClose,
    handleTooltipClose,
    tooltipOpen,
  } = useTreeItemMenu(
    handleDeleteClick,
    handleRestoreClick,
    setMenuOpen,
    moreVertVisible,
    setMoreVertVisible,
    setIsDuplicateAssetOpen,
    setOpenEditingForm,
    node,
    projectLevelButton
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <MoreVert
        aria-label={
          projectLevelButton ? 'open-project-asset-menu' : 'open-asset-menu'
        }
        aria-hidden="false"
        onClick={handleMenuOpen}
        sx={[
          moreVertVisible
            ? {
                display: 'flex',
              }
            : {
                display: 'none',
              },
        ]}
      />
      <Menu
        open={menuOpen}
        anchorEl={anchorEl}
        aria-label="asset-menu"
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{
          height: '300px',
        }}
      >
        {menuItems.map(item => {
          return item.action === 'copyId' ? (
            <ClickAwayListener
              key={item.action}
              onClickAway={handleTooltipClose}
            >
              <Tooltip
                placement="right"
                title="Copied"
                open={tooltipOpen}
                onClick={handleTooltipClose}
              >
                <MenuItem aria-label={item.action} onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              </Tooltip>
            </ClickAwayListener>
          ) : (
            <Tooltip
              placement="right"
              key={item.action}
              title={item.tooltipTitle}
            >
              <span>
                <MenuItem
                  onClick={item.onClick}
                  aria-label={item.action}
                  disabled={item.disabled}
                >
                  {item.label}
                </MenuItem>
              </span>
            </Tooltip>
          );
        })}
      </Menu>
    </div>
  );
};

export default TreeItemMenu;

import { useContext, Suspense } from 'react';
import { useParams, useSearchParams, useLocation } from 'react-router';
import { Grid2, Typography, CardContent } from '@mui/material';
import { LocalOffer, QueryStats, Description } from '@mui/icons-material';
import {
  GlobalTypesContext,
  PageLayoutContext,
  ProjectContext,
  ProjectPermissionContext,
} from 'context';
import { FoldingDrawer } from 'components/drawer';
import PageLayoutBox from 'components/layout/page-layout-styles';
import DataDrawerGrid from '../common/grid';
import Tags from '../common/tags';
import Statistics from '../common/statistics';
import { StyledBox } from '../parameters-page-styles';
import Header from './header';
import useProjectDetails from './hooks/use-project-details';

const ProjectDetails = () => {
  const { openDrawers, setOpenDrawers, numberOfOpen } =
    useContext(PageLayoutContext);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const { project } = useContext(ProjectContext);
  const { assetTagsAndTypes } = useContext(GlobalTypesContext);
  const { writePermission } = useContext(ProjectPermissionContext);
  const { projectTags, isTagLoading, setProjectTags } = useProjectDetails();

  return (
    <FoldingDrawer
      open={openDrawers.openAssetDetail}
      tooltip="Project details"
      width={numberOfOpen > 2 ? '20%' : '30%'}
      onClick={() =>
        setOpenDrawers(curr => {
          curr.openAssetDetail = !openDrawers.openAssetDetail;
        })
      }
    >
      <Suspense fallback={null}>
        <PageLayoutBox>
          <StyledBox aria-label="project-details" tabIndex={0}>
            <Header />
            <CardContent>
              <Grid2
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <DataDrawerGrid
                  icon={<Description />}
                  caption="Scope of Works"
                  content={
                    <Typography variant="caption" color="textSecondary">
                      {project.scopeOfWorks}
                    </Typography>
                  }
                />
                {pathname.includes('/parameters') && (
                  <DataDrawerGrid
                    icon={<QueryStats />}
                    caption="Project Statistics"
                    content={
                      <Statistics
                        title={
                          searchParams.get('project_parameter')
                            ? 'Project Parameters'
                            : 'All Parameters'
                        }
                      />
                    }
                  />
                )}
                <DataDrawerGrid
                  icon={<LocalOffer />}
                  caption="Tags"
                  content={
                    <Grid2 container aria-label="tags-component">
                      {assetTagsAndTypes.map(tagType => (
                        <Tags
                          referenceTable="project"
                          id={projectId}
                          existingTags={projectTags}
                          setExistingTags={setProjectTags}
                          tagType={tagType}
                          globalTags={tagType?.tags}
                          key={tagType?.id}
                          loading={isTagLoading}
                          permission={writePermission}
                        />
                      ))}
                    </Grid2>
                  }
                />
              </Grid2>
            </CardContent>
          </StyledBox>
        </PageLayoutBox>
      </Suspense>
    </FoldingDrawer>
  );
};

export default ProjectDetails;

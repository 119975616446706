import { Suspense, useContext } from 'react';
import { Stack, Typography, MenuItem, Divider } from '@mui/material';
import { FoldingDrawer } from 'components/drawer';
import { DataTemplatesContext, ThemeContext } from 'context';
import NavigationHeader from 'components/cards/navigation-header';
import { PageLayoutContext } from 'context/page-layout-context';
import { StyledSwitch } from 'components/styles/switch-styles';
import TemplatesList from 'components/data-templates-list';
import PageLayoutBox from 'components/layout/page-layout-styles';
import useTemplatesPage from 'routes/templates/hooks/use-templates-page';
import { StyledBox } from 'routes/parameters-page/parameters-page-styles';
import Filters from 'components/filters';
import handleDataTemplatesFilters from './hooks/handle-data-templates-filters';

const DataTemplates = () => {
  const {
    dataTemplates,
    loading,
    loadingAssets,
    handleOpenCloseWizard,
    rowSelectionModel,
    setRowSelectionModel,
  } = useContext(DataTemplatesContext);
  const { localTheme } = useContext(ThemeContext);
  const { openTemplatePanels, numberOfTemplatePanelsOpen } =
    useContext(PageLayoutContext);
  const { handleDataTemplatesChange } = useTemplatesPage();

  const {
    handleFilterActive,
    filterCount,
    openSwitchMenu,
    filterMenu,
    filters,
    sortByOptions,
    handleOnChange,
    clearFilters,
    applyFilters,
    handleOnCancel,
    filtersMap,
  } = handleDataTemplatesFilters();

  return (
    <FoldingDrawer
      open={openTemplatePanels.openTemplates}
      width={numberOfTemplatePanelsOpen > 2 ? '5%' : '20%'}
      tooltip="Templates"
      onClick={handleDataTemplatesChange}
    >
      <PageLayoutBox>
        <Suspense fallback={null}>
          <StyledBox aria-label="templates-page">
            <Suspense fallback={null}>
              <NavigationHeader
                open
                label="templates"
                handleClick={handleFilterActive}
                filterCount={filterCount}
                hasProjectCard
                handleAdd={handleOpenCloseWizard}
                disabled
              />
            </Suspense>
            <Divider />
            {openSwitchMenu ? (
              <Suspense fallback={null}>
                {filterMenu?.map(({ switchLabel, handleChange, isChecked }) => {
                  return (
                    <MenuItem
                      key={switchLabel}
                      checked={!isChecked}
                      onKeyDown={event => {
                        if (event.key === ' ' || event.key === 'Enter') {
                          event.preventDefault();
                          handleChange(event);
                        }
                      }}
                    >
                      <Stack direction="row">
                        <StyledSwitch
                          palette={localTheme}
                          aria-label="toggle-switch"
                          size="small"
                          checked={isChecked}
                          onChange={handleChange}
                        />
                        <Typography variant="body2">{switchLabel}</Typography>
                      </Stack>
                    </MenuItem>
                  );
                })}
                <Filters
                  sortByOptions={sortByOptions}
                  filters={filters}
                  applyFilters={applyFilters}
                  filtersMap={filtersMap}
                  handleOnChange={handleOnChange}
                  handleOnCancel={handleOnCancel}
                  clearFilters={clearFilters}
                />
              </Suspense>
            ) : (
              <TemplatesList
                style={{ width: '100%' }}
                rows={dataTemplates}
                loading={loading || loadingAssets}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
              />
            )}
          </StyledBox>
        </Suspense>
      </PageLayoutBox>
    </FoldingDrawer>
  );
};

export default DataTemplates;

import PT from 'prop-types';
import {
  Grid2,
  Typography,
  Skeleton,
  Divider,
  Chip,
  Tooltip,
} from '@mui/material';
import { lighten, darken } from '@mui/material/styles';
import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { ThemeContext } from 'context';
import Highlighted from 'components/highlighted';

function MainContent({ project, loading, searchValue }) {
  const { localTheme } = useContext(ThemeContext);
  const { confidential, scopeOfWorks, shortTitle, number, roles } = {
    ...project,
  };

  return (
    <Grid2
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {loading ? (
        <>
          <Skeleton
            sx={theme => ({
              height: theme.spacing(2),
              width: '40%',
            })}
            data-testid="loading"
          />
          <Skeleton
            sx={theme => ({
              height: theme.spacing(2),
              width: '40%',
            })}
            data-testid="loading"
          />
          <Skeleton
            sx={theme => ({
              height: theme.spacing(2),
              width: '40%',
            })}
            data-testid="loading"
          />
        </>
      ) : (
        <Tooltip title={shortTitle} placement="bottom">
          <Grid2 container sx={{ height: 100 }}>
            <Grid2 item size={confidential ? 8 : 12}>
              <Typography gutterBottom variant="body2" color="textSecondary">
                <Highlighted
                  text={`${'Project number'}: ${number}`}
                  highlight={searchValue}
                />
              </Typography>
            </Grid2>
            {confidential && (
              <Grid2 item size={4} data-cy="confidential-project-chip">
                <Typography variant="body2" color="error" fontWeight="bold">
                  CONFIDENTIAL
                </Typography>
              </Grid2>
            )}
            <Typography
              gutterBottom
              variant="h6"
              component="h2"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              <Highlighted text={shortTitle} highlight={searchValue} />
            </Typography>
          </Grid2>
        </Tooltip>
      )}
      {loading ? (
        <>
          <br />
          <Skeleton
            sx={theme => ({
              height: theme.spacing(2),
            })}
            data-testid="loading"
          />
          <Skeleton
            sx={theme => ({
              height: theme.spacing(2),
            })}
            data-testid="loading"
          />
          <Skeleton
            sx={theme => ({
              height: theme.spacing(2),
              width: '40%',
            })}
            data-testid="loading"
          />
        </>
      ) : (
        <Grid2 item sx={{ height: 140 }}>
          <Divider />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            sx={theme => ({
              paddingTop: theme.spacing(1),
            })}
          >
            {isEmpty(scopeOfWorks) || scopeOfWorks.length < 175
              ? scopeOfWorks
              : `${scopeOfWorks.slice(0, 175)}...`}
          </Typography>
        </Grid2>
      )}
      {!loading && (
        <Grid2 item sx={{ height: 100 }}>
          {roles?.map(role => {
            return (
              <Chip
                aria-label="roleChip"
                sx={[
                  theme => ({
                    margin: theme.spacing(0.25),
                    color: theme.palette.textSecondary,
                  }),
                  localTheme === 'dark'
                    ? {
                        backgroundColor: theme =>
                          darken(theme.palette.primary.main, 0.3),
                      }
                    : {
                        backgroundColor: theme =>
                          lighten(theme.palette.primary.main, 0.8),
                      },
                ]}
                label={role.name}
                key={role.name}
              />
            );
          })}
        </Grid2>
      )}
    </Grid2>
  );
}
MainContent.propTypes = {
  project: PT.shape({
    projectDirectorName: PT.string,
    centreCode: PT.string,
    projectManagerName: PT.string,
    scopeOfWorks: PT.string,
  }),
  loading: PT.bool.isRequired,
  searchValue: PT.string,
};

MainContent.defaultProps = {
  project: {
    projectDirectorName: '',
    centreCode: '',
    projectManagerName: '',
    scopeOfWorks: '',
  },
  searchValue: '',
};

export default MainContent;

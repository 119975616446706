import PT from 'prop-types';
import { Box, Grid2 } from '@mui/material';
import { useState, useEffect } from 'react';
import Alert from 'components/alerts';
import config from 'config';
import ProjectCard from './project-card';

function ProjectCardGrid({ projects, error, searchValue }) {
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (searchValue) {
      setExpanded(projects.map(project => project.projectId));
    }
  }, [projects, searchValue]);

  if (error)
    return (
      <Box
        sx={theme => ({
          padding: theme.spacing(2),
        })}
      >
        <Alert title={error.msg} text={error.details} />
      </Box>
    );
  return (
    <Grid2
      container
      justifyContent="center"
      alignContent="flex-start"
      spacing={2}
      sx={{
        minHeight: '90vh',
        width: '100%',
        margin: 0,
        padding: '16px 0',
      }}
    >
      {projects.map((project, i) => {
        return (
          <Grid2 key={project?.projectId || i} item>
            <ProjectCard
              project={project}
              loading={!project?.projectId}
              searchValue={searchValue}
              media={
                <img
                  src={`/illustrations/${config.env}/projectCard_${i % 6}.svg`}
                  alt={`Project card illustration ${project.shortTitle}`}
                />
              }
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </Grid2>
        );
      })}
    </Grid2>
  );
}

ProjectCardGrid.defaultProps = { error: undefined };

ProjectCardGrid.propTypes = {
  error: PT.shape({ msg: PT.string, details: PT.string }),
  projects: PT.arrayOf(
    PT.shape({
      shortTitle: PT.string,
      projectUrl: PT.string,
      number: PT.string,
      projectId: PT.string,
      projectDirectorName: PT.string,
      centreCode: PT.string,
      projectManagerName: PT.string,
      scopeOfWorks: PT.string,
    })
  ).isRequired,
  searchValue: PT.string.isRequired,
};

export default ProjectCardGrid;

import PT from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  Typography,
  TextField,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import StyledFormHelperText from './search-styles';

function Search({
  projectSearch,
  options,
  loading,
  addedProject,
  handleInputChange,
  handleSelection,
}) {
  const handleErrors = val => {
    let err;
    if (/[a-zA-Z]/g.test(val)) err = 'Only use numbers';
    if (isEmpty(val)) err = '';
    return err;
  };

  return (
    <>
      <Typography variant="subtitle2">
        Select the project you wish to add to Digital Design Brief. This project
        must already exist on Arup Projects. To add a project, search by its job
        number in the box below.
      </Typography>
      <br />
      <Autocomplete
        data-cy="add-project-search"
        disabled={!!addedProject}
        options={options}
        loading={loading}
        getOptionLabel={option => option.projectNumber}
        onChange={handleSelection}
        onInputChange={handleInputChange}
        renderOption={(props, option) => (
          <li {...props}>
            <Typography variant="body2" color="textSecondary">
              {`${option.projectNumber}: ${option.shortTitle}`}
            </Typography>
          </li>
        )}
        isOptionEqualToValue={(option, value) =>
          option.projectNumber === value.projectNumber
        }
        renderInput={params => (
          <TextField
            {...params}
            error={!isEmpty(handleErrors(projectSearch))}
            label="Project number"
            placeholder="e.g. 23456700, 60956798 or 07796701"
            size="small"
            helperText={
              <StyledFormHelperText>
                {!isEmpty(handleErrors(projectSearch))
                  ? handleErrors(projectSearch)
                  : null}
              </StyledFormHelperText>
            }
            slotProps={{
              input: {
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        data-testid="loading"
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              },

              htmlInput: {
                ...params.inputProps,
                maxLength: 8,
                type: 'tel',
                'aria-label': 'Project number',
              },

              formHelperText: {
                ...params.FormHelperTextProps,
                component: 'div',
              },
            }}
          />
        )}
      />
    </>
  );
}

Search.propTypes = {
  projectSearch: PT.oneOfType([PT.string, PT.number]),
  options: PT.arrayOf(
    PT.shape({
      shortTitle: PT.string.isRequired,
    })
  ).isRequired,
  loading: PT.bool.isRequired,
  addedProject: PT.oneOfType([PT.string, PT.bool]).isRequired,
  handleInputChange: PT.func.isRequired,
  handleSelection: PT.func.isRequired,
};

Search.defaultProps = {
  projectSearch: null,
};

export default Search;

/* eslint-disable no-unused-vars */
import PT from 'prop-types';
import { useContext, useCallback } from 'react';
import { Box, Button, Chip, Grid2, Typography, Divider } from '@mui/material';
import { lighten, darken, useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { DataGrid } from 'components/tables';
import Alert from 'components/alerts';
import { ThemeContext } from 'context';
import { StyledIconButton } from 'components/buttons';
import ParameterTypeCreator from '../parameter-type-creator';
import useImportParameterTypes from './hooks/use-import-parameter-types';

const ParameterTypeImporter = ({
  values,
  setFormAttribute,
  open,
  setOpenImportDrawer,
  availableParameterTypes,
  ...rest
}) => {
  const theme = useTheme();
  const { remove, push } = rest;
  const {
    rowSelectionModel,
    updateSelectionModel,
    openParameterCreator,
    setOpenParameterCreator,
    setRowSelectionModel,
    parameterTypeRows,
    afterKey,
    rowsLoading,
    setRowsLoading,
    getAllParameterTypes,
    setSearchString,
    searchString,
    handleSearch,
    error,
  } = useImportParameterTypes(push, availableParameterTypes, values, remove);
  const { localTheme } = useContext(ThemeContext);
  const columns = [
    {
      field: 'parameterTypeName',
      flex: 2,
      headerName: 'Parameter type',
      type: 'string',
      valueGetter: (value, row, column, apiRef) => row.parameterTypeName,
    },
    {
      field: 'dataType',
      flex: 1,
      headerName: 'Data Type',
      type: 'string',
      valueGetter: (value, row, column, apiRef) => row.dataType,
    },
    {
      field: 'globalParameter',
      flex: 1,
      headerName: 'Global Parameter Type',
      type: 'boolean',
      valueGetter: (value, row, column, apiRef) => row.globalParameter,
    },
    {
      field: 'currentAvailableParameter',
      flex: 2,
      headerName: 'Recommended Parameter for this Asset Type',
      type: 'boolean',
      valueGetter: (value, row, column, apiRef) =>
        row.currentAvailableParameter,
    },
  ];

  const CustomFooterStatusComponent = () => {
    return (
      <>
        <Divider />
        <Grid2 aria-label="customFooter" container justifyContent="flex-end">
          <Typography variant="body2" sx={{ padding: theme.spacing(1) }}>
            {afterKey
              ? `Loaded first ${parameterTypeRows?.length} rows.`
              : `Total Rows: ${parameterTypeRows?.length}`}
          </Typography>
        </Grid2>
      </>
    );
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dbounce = useCallback(debounce(handleSearch, 1000), []);

  const onFilterChange = filterModel => {
    setRowsLoading(true);
    const searchPhrase = filterModel.quickFilterValues.join(' ');
    setSearchString(searchPhrase);
    dbounce(searchPhrase);
  };

  const handleOnRowsScrollEnd = () => {
    if (afterKey) {
      getAllParameterTypes(afterKey, { search: searchString });
    }
  };

  return (
    <>
      {error && (
        <Box sx={{ padding: theme.spacing(3) }} aria-label="import-alert">
          <Alert title={error.msg} text={error.details} />
        </Box>
      )}
      {!openParameterCreator && (
        <Grid2 container>
          <Grid2 item size={11.5}>
            <DataGrid
              sx={{
                marginTop: theme.spacing(2),
                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                  {
                    display: 'none',
                  },
              }}
              footer={CustomFooterStatusComponent}
              aria-label="parameter-type-table"
              exportName="test"
              getRowId={row => row.parameterTypeId}
              showToolbar
              filterMode="server"
              onFilterModelChange={onFilterChange}
              onRowsScrollEnd={handleOnRowsScrollEnd}
              loading={rowsLoading}
              rows={parameterTypeRows}
              columns={columns}
              disableRowSelectionOnClick
              hideFooterPagination={false}
              density="standard"
              sortingOrder={['asc', 'desc']}
              height={400}
              rowHeight={50}
              noRowsMessage="test"
              searchToolbar
              checkboxSelection
              disableColumnFilter
              onRowSelectionModelChange={newRowSelectionModel => {
                updateSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
            />
          </Grid2>
          <Grid2 item size={0.5}>
            <StyledIconButton
              title="Close"
              icon={<Close />}
              onClick={() => setOpenImportDrawer(false)}
            />
          </Grid2>
          <Grid2 item size={12}>
            {values?.parameterTypes?.map(param => {
              return (
                <Chip
                  aria-label={`chip-${param.parameterTypeName}`}
                  sx={[
                    {
                      height: theme.spacing(5),
                      margin: theme.spacing(0.25),
                      marginTop: theme.spacing(1),
                    },
                    localTheme === 'dark'
                      ? {
                          backgroundColor: darken(
                            theme.palette.primary.main,
                            0.3
                          ),
                        }
                      : {
                          backgroundColor: lighten(
                            theme.palette.primary.main,
                            0.8
                          ),
                        },
                  ]}
                  key={param.id}
                  label={param.name}
                  icon={
                    param?.isNewParameterType ? (
                      <Chip
                        sx={{
                          marginTop: 2,
                          marginBottom: 2,
                          backgroundColor: theme.palette.background.paper,
                        }}
                        label={
                          <Typography variant="subTitle1" fontWeight="bold">
                            Custom
                          </Typography>
                        }
                      />
                    ) : null
                  }
                  onDelete={() => {
                    updateSelectionModel(
                      rowSelectionModel.filter(
                        id => id !== param.parameterTypeId
                      )
                    );
                  }}
                />
              );
            })}
          </Grid2>
          <Grid2 sx={{ paddingTop: theme.spacing(2) }} item size={4}>
            <Button
              data-cy="button"
              disabled={false}
              onClick={() => {
                setOpenParameterCreator(!openParameterCreator);
              }}
            >
              Add custom parameter
            </Button>
          </Grid2>
        </Grid2>
      )}
      {openParameterCreator && (
        <ParameterTypeCreator
          openParameterCreator={openParameterCreator}
          setOpenParameterCreator={setOpenParameterCreator}
          rowSelectionModel={rowSelectionModel}
          push={push}
          setRowSelectionModel={setRowSelectionModel}
          availableParameterTypes={availableParameterTypes}
        />
      )}
    </>
  );
};

ParameterTypeImporter.propTypes = {
  values: PT.shape({
    itemsImport: PT.arrayOf(
      PT.shape({ parameterTypeName: PT.string, id: PT.string })
    ),
    parameterTypes: PT.arrayOf(PT.shape({})),
  }).isRequired,
  setFormAttribute: PT.func.isRequired,
  open: PT.bool.isRequired,
  setOpenImportDrawer: PT.func.isRequired,
  availableParameterTypes: PT.arrayOf(PT.shape({})).isRequired,
  error: PT.shape({ msg: PT.string, details: PT.string }),
};

ParameterTypeImporter.defaultProps = { error: undefined };

export default ParameterTypeImporter;

import { styled, darken } from '@mui/material/styles';

export default styled('div')(({ theme }) => ({
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider.main}`,
  height: `calc(100vh - 64px - 56px)`,
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - 58px - 56px)`,
  },
  ...theme.applyStyles('dark', {
    backgroundColor: darken(theme.palette.secondary.main, 0.9),
  }),
}));

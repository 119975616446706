import { AllInbox } from '@mui/icons-material';
import { useMediaQuery, Button, Tooltip as ToolTip } from '@mui/material';
import { useNavigate } from 'react-router';
import config from 'config';

function Home() {
  const screenSize = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const navigate = useNavigate();

  return (
    <ToolTip title="Home">
      <Button
        component="span"
        size="large"
        sx={theme => ({
          marginRight: theme.spacing(2.5),
          whiteSpace: 'nowrap',
          textTransform: 'none',
        })}
        onClick={() => navigate('/')}
        data-cy="homeButton"
      >
        <AllInbox
          fontSize="large"
          sx={theme => ({
            marginRight: theme.spacing(1),
          })}
        />
        {screenSize ? `Digital Design Brief` : `DDB`}
        {config.env === 'ut' && ' Sandbox'}
      </Button>
    </ToolTip>
  );
}

export default Home;

import { useContext } from 'react';
import { Grid2, Box } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import qs from 'qs';
import { useSearchParams } from 'react-router';
import { AddButton, StyledIconButton } from 'components/buttons';
import ConfidentialChip from 'components/chips/confidential';
import { FavouritesButton } from 'components/favourites';
import { ProjectContext, ProjectPermissionContext } from 'context';
import FiltersButton from 'components/buttons/icons/filters';
import config from 'config';

export default (
  open,
  handleClick,
  handleAdd,
  disabled,
  filterCount,
  tooltipMessage,
  rest
) => {
  const { writePermission } = useContext(ProjectPermissionContext);
  const { project } = useContext(ProjectContext);
  const [searchParams] = useSearchParams();
  const { ...parsedSearch } = qs.parse(searchParams.toString());

  const actionButtons = {
    parameters: (
      <Grid2
        container
        item
        size={project?.confidential ? 5 : 4}
        justifyContent="flex-end"
        flexWrap="true"
      >
        <Grid2
          item
          display="flex"
          justifyContent="flex-end"
          size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 4 }}
          sx={{ paddingTop: '10px' }}
        >
          {project?.confidential && (
            <Box display="flex" justifyContent="flex-end" alignItems="centre">
              <ConfidentialChip iconOnly />
            </Box>
          )}
        </Grid2>
        <Grid2
          item
          display="flex"
          justifyContent="flex-end"
          size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 4 }}
          sx={theme => ({
            paddingRight: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
          })}
        >
          <FavouritesButton project={project} />
        </Grid2>
        <Grid2
          display="flex"
          justifyContent="flex-end"
          item
          size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 4 }}
        >
          <AddButton
            display="flex"
            justifyContent="flex-end"
            alignItems="centre"
            tooltip={
              writePermission
                ? 'Add new assets'
                : "You don't have permission to do this"
            }
            onClick={handleClick}
            disabled={!writePermission}
          />
        </Grid2>
      </Grid2>
    ),
    templates: (
      <Grid2 container size={4} item justifyContent="flex-end" flexWrap="true">
        <Grid2
          sx={theme => ({
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(0.5),
          })}
          display="flex"
          justifyContent="flex-end"
          item
          size={12}
        >
          <FiltersButton
            onClick={handleClick}
            filterCount={filterCount}
            {...rest}
          />
        </Grid2>
        {config.featureFlags.areTemplatesButtonsEnabled && (
          <Grid2
            display="flex"
            justifyContent="flex-end"
            item
            size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 4 }}
          >
            <AddButton
              onClick={handleAdd}
              disabled={disabled}
              tooltip={
                disabled ? "You don't have permission to do this" : 'Add'
              }
            />
          </Grid2>
        )}
      </Grid2>
    ),
    dataSets: (
      <Grid2
        container
        justifyContent={
          config.featureFlags.areTemplatesButtonsEnabled
            ? 'space-between'
            : 'flex-end'
        }
        alignItems="center"
        id="dataSetHeader"
        size={12}
        sx={theme => ({
          backgroundColor: theme.palette.action.hover,
        })}
      >
        {config.featureFlags.areTemplatesButtonsEnabled && (
          <Grid2 item>
            <FiltersButton
              filterCount={filterCount}
              {...rest}
              disabled
              onClick={handleClick}
            />
            <StyledIconButton
              title="Sort direction"
              onClick={() => {}}
              aria-label="Sort direction"
              color="default"
              disabled
              icon={
                parsedSearch.order === 'asc' ? (
                  <ArrowUpward data-testid="asc" />
                ) : (
                  <ArrowDownward data-testid="desc" />
                )
              }
            />
          </Grid2>
        )}
        <Grid2 item>
          <AddButton
            onClick={handleAdd}
            disabled={writePermission ? disabled : writePermission}
            tooltip={
              writePermission
                ? tooltipMessage
                : "You don't have permission to do this"
            }
          />
        </Grid2>
      </Grid2>
    ),
  };

  return { actionButtons };
};

import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router';
import { Button, Link, Grid2, Typography } from '@mui/material';
import { StyledStraplineTypography } from './styles';

const ProjectsLink = forwardRef((props, ref) => (
  <Link to="/projects" ref={ref} {...props} component={RouterLink} />
));

ProjectsLink.displayName = 'ProjectsLink';

const Description = () => {
  return (
    <>
      <StyledStraplineTypography variant="h5" component="p" color="inherit">
        Digital Design Brief (DDB) is Arup&apos;s platform for storing and
        managing the assets and parameters that define our projects.
      </StyledStraplineTypography>
      <Grid2 container>
        <Grid2
          item
          sx={theme => ({
            paddingBottom: theme.spacing(4),
          })}
        >
          <Button
            component={ProjectsLink}
            sx={{ marginTop: 4 }}
            variant="contained"
            data-cy="go-to-projects"
            color="primary"
          >
            <Typography variant="body2">Go To Projects</Typography>
          </Button>
        </Grid2>
      </Grid2>
    </>
  );
};
export default Description;

import { Link as LinkIcon, Source as SourceIcon } from '@mui/icons-material';
import PT from 'prop-types';
import {
  CardContent,
  Card,
  CardHeader,
  Typography,
  Grid2,
  Skeleton,
} from '@mui/material';
import { useParams } from 'react-router';
import { formatDate } from 'utils';
import isValidUrl from 'utils/is-valid-url';
import config from 'config';
import { StyledIconButton } from '../../buttons';

const SourceDetailCard = props => {
  const { projectId } = useParams();
  const {
    source: {
      title,
      reference,
      url: referenceUrl,
      dateDay,
      dateMonth,
      dateYear,
      sourceType,
      comment,
      id: sourceId,
    },
    id,
    loading,
    commentLoading,
  } = props;
  let date = dateYear;
  if (dateDay)
    date = formatDate(new Date(dateYear, dateMonth - 1, dateDay), undefined);
  if (dateMonth) formatDate(new Date(dateYear, dateMonth), undefined);

  const sourceTypeName = sourceType?.name; // TODO: Update to match correct shape when source updated
  const sourceProperties = [
    {
      caption: 'Publication Date',
      content: date || null,
    },
    {
      caption: 'Reference',
      content: reference, // TODO: Check if this still exists in the new model
    },
    {
      caption: 'Source Type',
      content: sourceTypeName,
    },
    {
      caption: 'Directory or URL',
      content: referenceUrl,
    },
  ];
  if (config.featureFlags.isSourceCommentEnabled) {
    sourceProperties.push({
      caption: 'Comment',
      content: comment,
    });
  }

  return loading || commentLoading ? (
    <Card>
      <Skeleton height={30} width="100%" display="inline" />
    </Card>
  ) : (
    <Card>
      <CardHeader
        aria-label="card-header"
        avatar={
          <StyledIconButton
            aria-label="link-to-source"
            title="Link to Source"
            href={`/projects/${projectId}/sources/${sourceId}`}
            icon={<SourceIcon aria-label="source-icon" />}
          />
        }
        title={title}
        subheader={`${sourceProperties[0].caption} ${sourceProperties[0].content}`}
        action={
          isValidUrl(referenceUrl) && (
            <StyledIconButton
              aria-label="link-to-url"
              title={`Link to ${referenceUrl}`}
              onClick={() => window.open(referenceUrl, '_blank')}
              icon={<LinkIcon />}
            />
          )
        }
      />
      <CardContent aria-label="card-content">
        <Grid2 container>
          {sourceProperties.map((property, index) => {
            return (
              index !== 0 && (
                <Grid2
                  size={12}
                  key={`grid-${property.caption}-${id}`}
                  container
                >
                  <Grid2
                    key={`grid-caption-${property.caption}-${id}`}
                    item
                    size={{ md: 4, xs: 12 }}
                  >
                    <Typography
                      aria-label="caption"
                      key={`caption-${property.caption}-${id}`}
                      variant="caption"
                      color="textSecondary"
                      style={{ wordWrap: 'break-word' }}
                    >
                      {property.caption}
                    </Typography>
                  </Grid2>
                  <Grid2
                    key={`grid-content-${property.caption}-${id}`}
                    item
                    size={{ md: 8, xs: 12 }}
                  >
                    <Typography
                      aria-label="content"
                      key={`content-${property.caption}-${id}`}
                      variant="body2"
                      style={{ wordWrap: 'break-word' }}
                    >
                      {property.content}
                    </Typography>
                  </Grid2>
                </Grid2>
              )
            );
          })}
        </Grid2>
      </CardContent>
    </Card>
  );
};

SourceDetailCard.propTypes = {
  source: PT.shape({
    title: PT.string.isRequired,
    id: PT.string.isRequired,
    reference: PT.string,
    url: PT.string,
    dateDay: PT.string,
    dateMonth: PT.string,
    dateYear: PT.string,
    sourceType: PT.shape({ name: PT.string.isRequired }),
    comment: PT.string,
  }),
  id: PT.string,
  loading: PT.bool,
  commentLoading: PT.bool,
};

SourceDetailCard.defaultProps = {
  source: PT.shape({
    reference: '',
    url: '',
    dateDay: '',
    dateMonth: '',
    dateYear: '',
    comment: '',
  }),
  id: new Date().toString(),
  loading: true,
  commentLoading: true,
};
export default SourceDetailCard;

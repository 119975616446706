import { styled } from '@mui/material/styles';
import { Grid2 } from '@mui/material';

const Container = styled(Grid2)(({ theme }) => ({
  height: `calc(100vh - 64px)`,
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - 58px)`,
  },
  flexGrow: 1,
  width: '95%',
  alignItems: 'center',
  overflow: 'auto',
}));

export default Container;

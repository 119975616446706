/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import { Button, Grid2, Typography } from '@mui/material';
import { darken, lighten } from '@mui/material/styles';
import { useNavigate, createSearchParams, useLocation } from 'react-router';
import qs from 'qs';

const ProjectParameterButton = () => {
  const { search } = useLocation();

  const { project_parameter: projectParameters } = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
  const navigate = useNavigate();
  const paramsValues = {};
  paramsValues.order = 'asc';
  paramsValues.sort_by = 'parameter_type_name';

  return (
    <Grid2
      container
      justifyContent={'space-between'}
      size={12}
      aria-label="project-parameters"
      sx={theme => ({
        backgroundColor: !projectParameters
          ? theme.palette.background.paper
          : theme.palette.mode === 'dark'
            ? `${darken(theme.palette.primary.main, 0.7)}  !important`
            : `${lighten(theme.palette.primary.main, 0.8)} !important`,
        '&:hover': {
          backgroundColor: `${theme.palette.action.selected}`,
        },
      })}
    >
      <Button
        variant="text"
        aria-label="project-parameters-button"
        sx={theme => ({
          width: `calc(100% - 16px)`,
          justifyContent: 'flex-start',
          textTransform: 'none',
          paddingLeft: theme.spacing(2.5),
          paddingButton: 0,
          '& .MuiTouchRipple-child': {
            left: theme.spacing(-12.5),
          },
          backgroundColor: 'transparent',
        })}
        onClick={() => {
          paramsValues.project_parameter = true;
          navigate({
            pathname: `parameters`,
            search: `?${createSearchParams(paramsValues)}`,
          });
        }}
      >
        <Typography variant="body2">Project</Typography>
      </Button>
    </Grid2>
  );
};
export default ProjectParameterButton;

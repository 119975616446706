import PT from 'prop-types';
import { Info, Close } from '@mui/icons-material';
import { Grid2, Tooltip } from '@mui/material';
import { StyledIconButton } from 'components/buttons';

function Title({ handleClose }) {
  return (
    <Grid2 container alignItems="center">
      <Grid2 item size={11}>
        Add a new Project
        <Tooltip
          arrow
          title="The project number should: - Be between 5 and 8 digits long - Have no suffix - Not be a duplicate of an existing project in DDB"
          placement="right"
        >
          <Info color="disabled" data-testid="info" />
        </Tooltip>
      </Grid2>
      <Grid2 item size={1}>
        <StyledIconButton
          title="Close"
          onClick={handleClose}
          icon={<Close />}
        />
      </Grid2>
    </Grid2>
  );
}

Title.propTypes = {
  handleClose: PT.func.isRequired,
};

export default Title;

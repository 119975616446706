import { Feedback as FeedbackIcon } from '@mui/icons-material';
import PT from 'prop-types';
import { MenuItem, Typography, Button, useMediaQuery } from '@mui/material';
import config from 'config';
import { StyledIconButton } from '../../../buttons';

function Feedback({ title, handleClose }) {
  const isSmallScreen = useMediaQuery('(max-width:1100px)');

  return (
    <>
      {title && (
        <MenuItem
          component="a"
          color="inherit"
          data-cy="feedback-button"
          onClick={() => {
            window.open(config.url.feedback, '_blank');
            handleClose();
          }}
          aria-label="Provide Feedback"
        >
          <FeedbackIcon data-testid="feedback" />
          <Typography
            variant="button"
            sx={theme => ({
              paddingLeft: theme.spacing(1),
            })}
          >
            {title}
          </Typography>
        </MenuItem>
      )}
      {!title && isSmallScreen && (
        <StyledIconButton
          title="Provide Feedback"
          aria-label="Provide Feedback"
          data-cy="feedback-button"
          component="a"
          onClick={() => window.open(config.url.feedback, '_blank')}
          icon={<FeedbackIcon data-testid="feedback" />}
        />
      )}
      {!title && !isSmallScreen && (
        <Button
          title="Provide Feedback"
          component="a"
          onClick={() => window.open(config.url.feedback, '_blank')}
          aria-label="Provide Feedback"
          data-cy="feedback-button"
          startIcon={<FeedbackIcon data-testid="feedback" />}
        >
          Provide Feedback
        </Button>
      )}
    </>
  );
}

Feedback.propTypes = {
  title: PT.string,
  handleClose: PT.func,
};

Feedback.defaultProps = {
  title: null,
  handleClose: null,
};

export default Feedback;

/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import { Grid2, Skeleton } from '@mui/material';
import PT from 'prop-types';
import { List as ListIcon } from '@mui/icons-material';
import { SimpleTreeView } from '@mui/x-tree-view';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { Suspense, useContext } from 'react';
import EmptyState from 'components/empty-state';
import { GlobalTypesContext, AssetContext } from 'context';
import useAssets from './hooks/use-assets';
import TreeItems from './tree-items';
import ProjectParameterButton from './project-parameters';

const Assets = ({
  assetNodes,
  loading,
  isProjectAssetHierarchy,
  expanded,
  setExpanded,
}) => {
  const { assetId } = useParams();
  const { selectedNode, setSelectedNode } = useAssets(
    assetNodes,
    isProjectAssetHierarchy,
    expanded,
    setExpanded
  );

  const { assetTypeTrees } = useContext(GlobalTypesContext);
  const { assetsLoading } = useContext(AssetContext);
  const hierarchy = isProjectAssetHierarchy ? assetNodes : assetTypeTrees;

  const paramsValues = {};
  paramsValues.order = 'asc';
  paramsValues.sort_by = 'parameter_type_name';

  return (
    <Grid2
      aria-label="asset-tree-navigation"
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
      }}
    >
      <ProjectParameterButton assetNodes={assetNodes} />
      <SimpleTreeView expandedItems={expanded || []} selectedItems={[assetId]}>
        {(loading || assetsLoading) && isEmpty(hierarchy) ? (
          [...Array(1).keys()].map(load => (
            <Skeleton
              aria-label="loading"
              key={load}
              width="100%"
              height="30px"
            />
          ))
        ) : !loading && isEmpty(hierarchy) ? (
          <EmptyState
            Icon={ListIcon}
            title="No Assets"
            subtitle="Try adding a new asset using the button above"
          />
        ) : (
          <Suspense
            fallback={
              <Skeleton aria-label="loading" width="100%" height="30px" />
            }
          >
            {hierarchy.map(node => {
              return (
                <TreeItems
                  isHierarchy={isProjectAssetHierarchy}
                  node={node}
                  key={node.id}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  selectedNode={selectedNode}
                  setSelectedNode={setSelectedNode}
                />
              );
            })}
          </Suspense>
        )}
      </SimpleTreeView>
    </Grid2>
  );
};
Assets.propTypes = {
  loading: PT.bool,
  isProjectAssetHierarchy: PT.bool,
  assetNodes: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
      isAssetInstance: PT.bool,
      children: PT.array,
    })
  ),
  expanded: PT.arrayOf(PT.string).isRequired,
  setExpanded: PT.func.isRequired,
};

Assets.defaultProps = {
  isProjectAssetHierarchy: true,
  loading: false,
  assetNodes: [],
};
export default Assets;

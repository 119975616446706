import PT from 'prop-types';
import { Card, CardActions, CardActionArea, CardContent } from '@mui/material';
import { Link as RouterLink } from 'react-router';
import ActionHeader from '../action-header';

function ActionCard({
  loading,
  children,
  header,
  actions,
  actionArea,
  collapseContent,
  ...rest
}) {
  return (
    <Card sx={{ width: 315 }} {...rest}>
      {header && <ActionHeader isLoading={loading} {...header} />}
      <CardActionArea
        component={RouterLink}
        to={actionArea.link}
        data-testid="card-action-area"
        data-cy={actionArea['data-cy']}
        disabled={loading}
        sx={{ height: 340 }}
      >
        <CardContent sx={{ height: '100%' }}>{children}</CardContent>
      </CardActionArea>
      {actions && (
        <CardActions
          sx={{ paddingTop: 0 }}
          data-testid="card-actions"
          disableSpacing
          data-cy="card-action"
        >
          {actions}
        </CardActions>
      )}
      <CardContent sx={{ padding: 0, '&:last-child': { padding: 0 } }}>
        {collapseContent}
      </CardContent>
    </Card>
  );
}
ActionCard.propTypes = {
  loading: PT.bool.isRequired,
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
  header: PT.shape({
    renderAvatar: PT.node,
    renderTitle: PT.oneOfType([PT.string, PT.bool, PT.node]),
    renderAction: PT.node,
    renderSubheader: PT.oneOfType([PT.shape({}), PT.string]),
    style: PT.string,
  }),
  actionArea: PT.shape({ link: PT.string, 'data-cy': PT.string }),
  actions: PT.node,
  media: PT.node.isRequired,
  collapseContent: PT.node,
};
ActionCard.defaultProps = {
  header: null,
  actions: null,
  actionArea: null,
  collapseContent: null,
};
export default ActionCard;

/* eslint-disable no-nested-ternary */
import PT from 'prop-types';
import { isEmpty } from 'lodash';
import {
  ChevronRight,
  ExpandMore,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material';
import { Checkbox, Grid2, Radio } from '@mui/material';
import { useContext } from 'react';
import { DataSetTreeItem } from 'components/tree/tree-item';
import { ThemeContext } from 'context';
import StyledTreeIconButton from './tree-items-styles';

const TreeItems = ({
  node,
  updateSelection,
  selectedAssets,
  onAddAssetClick,
  onEditClick,
  onDoneClick,
  textFieldError,
  onClearClick,
  selectedNode,
  onRemoveAssetClick,
  values,
  change,
}) => {
  const { localTheme } = useContext(ThemeContext);

  const onOpenClick = () => {
    change('expanded', [...values.expanded, node.id]);
  };

  const findSelectedAssets = itemId => {
    const foundAsset = selectedAssets.find(
      selectedAsset => selectedAsset?.id === itemId
    );
    return !!foundAsset;
  };

  return (
    <DataSetTreeItem
      key={node?.id}
      itemId={node?.id}
      node={node}
      labelText={
        !node.isAssetInstance
          ? `${node.name} (${
              node.possibleAssetSubTypes.length >= 2
                ? 'Select all sub-types'
                : node.assetTypeMaxCount
                  ? `${'Select'} ${node.assetTypeMaxCount}`
                  : 'Min.1 req.'
            })`
          : node?.name
      }
      classes={{
        label: node.isAssetInstance ? 'dataSetInstanceLabel' : 'typeLabel',
        iconContainer: node.isAssetInstance
          ? 'dataSetInstanceLabel'
          : !node.isAssetInstance
            ? 'dataSetTypeLabel'
            : 'templateDetailsTree',
      }}
      labelInfo={
        node?.children?.length > 0 && !node?.isAssetInstance
          ? `(${node.children.length})`
          : ''
      }
      isAssetInstance={node.isAssetInstance}
      deleted={node.deletedAt}
      slots={{
        icon: () => (
          <Grid2 container>
            <Grid2 item size={6}>
              {!isEmpty(node.children) && !values.expanded.includes(node.id) ? (
                <StyledTreeIconButton
                  sx={{ padding: 0.75 }}
                  mode={localTheme}
                  title={`Expand ${node.name}`}
                  data-cy="tree-expand-icon"
                  onClick={onOpenClick}
                  icon={<ChevronRight />}
                />
              ) : (
                !isEmpty(node.children) &&
                values.expanded.includes(node.id) && (
                  <StyledTreeIconButton
                    sx={{ padding: 0.75 }}
                    mode={localTheme}
                    data-cy="tree-collapse-icon"
                    title={`Collapse ${node.name}`}
                    onClick={() => {
                      change(
                        'expanded',
                        values.expanded.filter(nodes => nodes !== node.id)
                      );
                    }}
                    icon={<ExpandMore />}
                  />
                )
              )}
            </Grid2>
            {node.isAssetInstance && (
              <Grid2 item size={6}>
                {node.assetTypeMaxCount === 1 && !node.multipleSubtypes ? (
                  <Radio
                    aria-label="select-asset-radio"
                    value={node.id}
                    checked={findSelectedAssets(node.id)}
                    onClick={() => updateSelection(node)}
                  />
                ) : (
                  <Checkbox
                    data-cy="select-asset"
                    aria-label="select-asset-multiple"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    sx={theme => ({
                      marginRight: theme.spacing(2),
                      backgroundColor: 'primary',
                    })}
                    checked={findSelectedAssets(node.id)}
                    onClick={() => updateSelection(node)}
                  />
                )}
              </Grid2>
            )}
          </Grid2>
        ),
      }}
      // eslint-disable-next-line no-confusing-arrow
      color={theme =>
        node?.isAssetInstance ? theme.palette.primary.main : theme.palette.text
      }
      textWeight={node?.isAssetInstance ? 400 : 700}
      onAddAssetClick={onAddAssetClick}
      onEditClick={onEditClick}
      onClearClick={onClearClick}
      onDoneClick={onDoneClick}
      textFieldError={textFieldError}
      onRemoveAssetClick={onRemoveAssetClick}
    >
      {!isEmpty(node.children)
        ? node.children.map(child => {
            if (Array.isArray(child)) {
              return child.reduce((allChildren, grandChild) => {
                if (grandChild) {
                  return (
                    <TreeItems
                      node={grandChild}
                      key={grandChild.id}
                      updateSelection={updateSelection}
                      selectedAssets={selectedAssets}
                      onAddAssetClick={onAddAssetClick}
                      onEditClick={onEditClick}
                      onDoneClick={onDoneClick}
                      textFieldError={textFieldError}
                      onClearClick={onClearClick}
                      selectedNode={selectedNode}
                      onRemoveAssetClick={onRemoveAssetClick}
                      values={values}
                      change={change}
                    />
                  );
                }
                return allChildren;
              }, {});
            }
            return (
              <TreeItems
                node={child}
                key={child.id}
                updateSelection={updateSelection}
                selectedAssets={selectedAssets}
                onAddAssetClick={onAddAssetClick}
                onEditClick={onEditClick}
                onDoneClick={onDoneClick}
                textFieldError={textFieldError}
                onClearClick={onClearClick}
                selectedNode={selectedNode}
                onRemoveAssetClick={onRemoveAssetClick}
                values={values}
                change={change}
              />
            );
          })
        : null}
    </DataSetTreeItem>
  );
};
TreeItems.propTypes = {
  node: PT.shape({
    id: PT.string,
    name: PT.string,
    isAssetInstance: PT.bool,
    children: PT.arrayOf(PT.shape({})),
    deletedAt: PT.string,
    hasChildren: PT.bool,
    checked: PT.bool,
    parentChecked: PT.bool,
    isTypeOnly: PT.bool,
    assetTypeMaxCount: PT.number || PT.null,
    multipleSubtypes: PT.bool,
    assetSubType: PT.shape({}) || null,
    possibleAssetSubTypes: PT.arrayOf(PT.shape({})) || null,
  }),
  updateSelection: PT.func,
  selectedAssets: PT.arrayOf(PT.oneOfType([PT.string, PT.shape({})])),
  onAddAssetClick: PT.func,
  onEditClick: PT.func,
  onDoneClick: PT.func,
  textFieldError: PT.string,
  onClearClick: PT.func,
  selectedNode: PT.string,
  onRemoveAssetClick: PT.func,
  change: PT.func,
  values: PT.shape({
    selectedAssets: PT.arrayOf(PT.shape({})),
    expanded: PT.arrayOf(PT.string),
  }),
};
TreeItems.defaultProps = {
  node: {},
  selectedAssets: [],
  onAddAssetClick: () => {},
  updateSelection: () => {},
  onEditClick: () => {},
  onDoneClick: () => {},
  textFieldError: '',
  onClearClick: () => {},
  selectedNode: '',
  onRemoveAssetClick: () => {},
  change: () => {},
  values: {},
};
export default TreeItems;

import { Grid2 } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Grid2)(({ theme }) => ({
  height: `calc(100vh - 64px)`,
  width: '75vw',
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - 58px)`,
  },
}));

export default StyledContainer;

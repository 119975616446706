import { Description } from '@mui/icons-material';
import PT from 'prop-types';
import { MenuItem, Typography, Button, useMediaQuery } from '@mui/material';
import config from '../../../../config';
import { StyledIconButton } from '../../../buttons';

function Docs({ title, handleClose }) {
  const docsUrl = config.url.docs;
  const isSmallScreen = useMediaQuery('(max-width:1100px)');

  return (
    <>
      {title && (
        <MenuItem
          component="a"
          color="inherit"
          data-cy="docs-button"
          data-testid="docs-button"
          onClick={() => {
            window.open(docsUrl, '_blank', 'noreferrer');
            handleClose();
          }}
          aria-label="Documentation"
        >
          <Description data-testid="docs" />
          <Typography
            variant="button"
            sx={theme => ({
              paddingLeft: theme.spacing(1),
            })}
          >
            {title}
          </Typography>
        </MenuItem>
      )}
      {!title && isSmallScreen && (
        <StyledIconButton
          title="Documentation"
          aria-label="Documentation"
          data-cy="docs-button"
          component="a"
          onClick={() => window.open(docsUrl, '_blank', 'noreferrer')}
          icon={<Description data-testid="docs" />}
        />
      )}
      {!title && !isSmallScreen && (
        <Button
          title="Documentation"
          component="a"
          onClick={() => {
            window.open(docsUrl, '_blank', 'noreferrer');
          }}
          aria-label="Documentation"
          data-testid="docs-button"
          data-cy="docs-button"
          startIcon={<Description data-testid="docs" />}
        >
          DOCUMENTATION
        </Button>
      )}
    </>
  );
}

Docs.propTypes = {
  title: PT.string,
  handleClose: PT.func,
};

Docs.defaultProps = {
  title: null,
  handleClose: null,
};

export default Docs;

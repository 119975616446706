import PT from 'prop-types';
import { Grid2 } from '@mui/material';

function DataDrawerGrid(props) {
  const { icon, caption, content, alert } = props;
  return (
    <Grid2
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      wrap="nowrap"
    >
      {alert && (
        <Grid2
          sx={theme => ({
            width: '100%',
            padding: theme.spacing(1),
          })}
          item
        >
          {alert}
        </Grid2>
      )}
      <Grid2
        direction="row"
        item
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        wrap="nowrap"
        size={12}
      >
        {icon && (
          <Grid2
            sx={theme => ({
              padding: theme.spacing(1),
            })}
            item
          >
            {icon}
          </Grid2>
        )}
        <Grid2
          item
          sx={theme => ({
            width: '100%',
            padding: theme.spacing(1),
          })}
        >
          <Grid2 item>{caption}</Grid2>
          <Grid2 item>{content}</Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
DataDrawerGrid.propTypes = {
  icon: PT.node.isRequired,
  caption: PT.node.isRequired,
  content: PT.node.isRequired,
  alert: PT.node,
};
DataDrawerGrid.defaultProps = {
  alert: null,
};
export default DataDrawerGrid;

import { Grid2, Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useContext, useState, useRef, useEffect } from 'react';
import WarningDialog from 'components/dialogs/warning';
import { AssetContext } from 'context';
import ToggleFilter from 'components/toggle-filter';
import Column from './column';

const AddAssets = () => {
  const { assetWizardColumns } = useContext(AssetContext);
  const [removeAssetWarning, setRemoveAssetWarning] = useState({ open: false });

  const divRef = useRef(null);

  useEffect(() => {
    divRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'end',
    });
  }, [assetWizardColumns]);

  return (
    <>
      {removeAssetWarning?.open && (
        <WarningDialog
          open={removeAssetWarning.open}
          handleAction={removeAssetWarning.onAccept}
          handleClose={removeAssetWarning.onClose}
          title={removeAssetWarning.title}
          body={removeAssetWarning.body}
          primaryButtonText={
            removeAssetWarning.primaryButtonText
              ? removeAssetWarning.primaryButtonText
              : 'Close'
          }
          secondaryButtonText="Cancel"
        />
      )}
      <Grid2 container direction="row">
        <ToggleFilter type="deletedAssetFilter" />
      </Grid2>
      <Box
        aria-label="add-assets-step"
        sx={{ display: 'flex', height: '100%' }}
      >
        {!isEmpty(assetWizardColumns) &&
          assetWizardColumns.map((column, index) => {
            return (
              <Column
                key={`asset-wizard-column-${index}`}
                columnIndex={index}
                assets={column}
                setRemoveAssetWarning={setRemoveAssetWarning}
              />
            );
          })}
        <div ref={divRef} />
      </Box>
    </>
  );
};

export default AddAssets;

import { useContext } from 'react';
import PT from 'prop-types';
import {
  Grid2,
  Stack,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { Tune as TuneIcon, UnfoldMore, UnfoldLess } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { AssetContext, ThemeContext } from 'context';
import ToggleFilter from 'components/toggle-filter';
import { StyledSwitch } from 'components/styles/switch-styles';
import useFilter from './hooks/use-filter';

const Filter = ({ menu, setExpanded, expanded }) => {
  const { localTheme } = useContext(ThemeContext);
  const {
    handleExpandAll,
    handleCollapseAll,
    handleClick,
    handleClose,
    openSwitchMenu,
    anchorEl,
  } = useFilter(setExpanded, expanded);
  const { theAsset, assetsLoading } = useContext(AssetContext);

  return (
    <Grid2 container justifyContent="flex-end" aria-label="navigationActions">
      <Grid2 item>
        <Tooltip title="Collapse All">
          <span>
            <IconButton
              aria-label="collapseAll"
              onClick={handleCollapseAll}
              disabled={!expanded.length}
            >
              <UnfoldLess aria-label="unfold-less-icon" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={
            !theAsset.id && !expanded.includes(theAsset.id)
              ? 'Select an Asset branch to expand'
              : 'Expand Branch'
          }
        >
          <span>
            <IconButton
              aria-label="expandAll"
              onClick={handleExpandAll}
              disabled={
                !theAsset.id || isEmpty(theAsset.children) || assetsLoading
              }
            >
              <UnfoldMore aria-label="unfold-more-icon" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Navigation Settings">
          <span>
            <IconButton onClick={handleClick} aria-label="navigationSettings">
              <TuneIcon aria-label="filter-icon" />
            </IconButton>
          </span>
        </Tooltip>
      </Grid2>
      <Menu
        aria-label="toggle-menu"
        open={openSwitchMenu}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClick}
        PaperProps={{
          elevation: 5,
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {menu.map(({ switchLabel, handleChange, isChecked, disabled }) => {
          return (
            <MenuItem
              key={switchLabel}
              onKeyDown={event => {
                if (event.key === ' ' || event.key === 'Enter') {
                  event.preventDefault();
                  handleChange();
                }
              }}
            >
              <Stack direction="row">
                <StyledSwitch
                  palette={localTheme}
                  aria-label={switchLabel}
                  size="small"
                  checked={isChecked}
                  onChange={handleChange}
                  disabled={disabled}
                />
                <Typography variant="body2">{switchLabel}</Typography>
              </Stack>
            </MenuItem>
          );
        })}
        <ToggleFilter type="deletedAssetFilter" />
      </Menu>
    </Grid2>
  );
};

Filter.propTypes = {
  menu: PT.arrayOf(
    PT.shape({
      switchLabel: PT.string,
      handleChange: PT.func,
      isChecked: PT.bool,
      disabled: PT.bool,
    })
  ).isRequired,
  setExpanded: PT.func.isRequired,
  expanded: PT.arrayOf(PT.string).isRequired,
};

export default Filter;

/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-confusing-arrow */
import PT, { string } from 'prop-types';
import { isEmpty } from 'lodash';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import {
  CircularProgress,
  Grid2,
  Tooltip,
  Dialog,
  DialogContent,
  Chip,
  useMediaQuery,
} from '@mui/material';
import {
  useSearchParams,
  useLocation,
  createSearchParams,
  useNavigate,
} from 'react-router';
import qs from 'qs';
import { useContext, useState } from 'react';
import { Box } from '@mui/system';
import RemovedAssetIcon from 'components/Icons/removed-asset';
import { AssetNavigationTreeItem } from 'components/tree/tree-item';
import { AssetContext, ThemeContext } from 'context';
import useHeaders from 'routes/parameters-page/asset-details-pane/header/hooks';
import WarningDialog from 'components/dialogs/warning';
import EditAsset from 'routes/parameters-page/asset-details-pane/header/edit-asset';
import DuplicateAsset from './tree-item-menu/duplicate-asset';
import StyledTreeIconButton from './tree-items-styles';
import TreeItemMenu from './tree-item-menu';

const TreeItems = ({
  node,
  expanded,
  isHierarchy,
  setExpanded,
  selectedNode,
  setSelectedNode,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const { ...parsedSearch } = qs.parse(searchParams.toString());
  const searchString = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
  const {
    theAsset,
    getAssets,
    assetsLoading,
    allAssets,
    isExpanding,
    getAsset,
    setTheAsset,
    setError,
  } = useContext(AssetContext);
  let setAsset = () => {};
  if (theAsset?.id === node.id) {
    setAsset = setTheAsset;
  }
  const { handleDeleteClick, displayWarning, handleRestoreClick } = useHeaders(
    node,
    setAsset
  );
  const showDeleted = parsedSearch.show_deleted_assets === 'true';
  delete searchString.project_parameter;
  const { localTheme } = useContext(ThemeContext);
  const [isDuplicateAssetOpen, setIsDuplicateAssetOpen] = useState(false);
  const [openEditingForm, setOpenEditingForm] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [moreVertVisible, setMoreVertVisible] = useState(false);
  const getSetMoreVertVisible = () =>
    menuOpen ? () => {} : setMoreVertVisible;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const onOpenClick = () => {
    if (!isHierarchy) return;
    if (!node.id.includes('_')) {
      if (node.id !== theAsset.id) {
        const selected = allAssets.find(asset => asset.id === node.id);
        const chunkSize = 99;

        Array.from(
          { length: Math.ceil(selected.children.length / chunkSize) },
          (_, i) =>
            getAssets({
              asset_id: selected.children.slice(
                i * chunkSize,
                i * chunkSize + chunkSize
              ),
            })
        );
      }
    }
    setExpanded(curr => [...curr, node.id]);
  };

  const onNodeToggle = () => {
    if (!isHierarchy) return;
    navigate({
      pathname: `asset/${node.id}/parameters`,
      search: `?${createSearchParams({
        ...searchString,
      })}`,
    });
  };

  const deletedStyleColor = theme =>
    showDeleted && node?.deletedAt
      ? theme.palette.secondary.dark
      : theme.palette.primary.main;

  const deletedChildren =
    !isEmpty(node.children) &&
    node?.children?.every(child => {
      return child?.deletedAt !== null;
    });

  return (
    (!isHierarchy ||
      showDeleted ||
      node.isTypeOnly ||
      (!showDeleted && node?.deletedAt === null)) && (
      <>
        <AssetNavigationTreeItem
          setMoreVertVisible={getSetMoreVertVisible()}
          key={node?.id}
          itemId={node?.id}
          node={node}
          labelInfo={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                flexWrap: 'wrap',
              }}
            >
              {isHierarchy && (
                <Tooltip
                  title={`${node.name} - ${node.assetTypeName}`}
                  placement="left"
                >
                  <Chip
                    key={node.id}
                    aria-label="asset-type-chip"
                    size="small"
                    label={node.assetTypeName}
                    sx={{
                      display: 'flex',
                      right: 20,
                      margin: '0 8px',
                      color: theme => theme.palette.getContrastText,
                      fontSize: '0.75rem',
                      height: '20px',
                      maxWidth: isSmallScreen ? '30px' : '90px',
                    }}
                  />
                </Tooltip>
              )}
              {isHierarchy && (
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '16px', // Fixed width to reserve space for TreeItemMenu
                  }}
                >
                  <TreeItemMenu
                    handleDeleteClick={handleDeleteClick}
                    handleRestoreClick={handleRestoreClick}
                    setMenuOpen={setMenuOpen}
                    moreVertVisible={moreVertVisible}
                    setMoreVertVisible={setMoreVertVisible}
                    setIsDuplicateAssetOpen={setIsDuplicateAssetOpen}
                    setOpenEditingForm={setOpenEditingForm}
                    menuOpen={menuOpen}
                    node={node}
                  />
                </Box>
              )}
            </div>
          }
          labelText={node?.name}
          classes={{
            label: menuOpen ? 'menu-open' : 'instanceLabel',
            iconContainer: 'assetNavigationTree',
          }}
          labelIcon={
            showDeleted &&
            node.deletedAt && (
              <RemovedAssetIcon
                sx={theme => ({
                  paddingRight: theme.spacing(0.5),
                  color: theme.palette.secondary.dark,
                })}
              />
            )
          }
          onLabelClick={onNodeToggle}
          slots={{
            icon: () => (
              <Grid2 container>
                <Grid2 item size={12}>
                  {(assetsLoading &&
                    node.hasChildren &&
                    isEmpty(node.children) &&
                    expanded.includes(node.id)) ||
                  (assetsLoading &&
                    (isExpanding || selectedNode === node.id) &&
                    node.id === theAsset.id) ? (
                    <CircularProgress size="50%" aria-label="loading-spinner" />
                  ) : node.hasChildren &&
                    (!deletedChildren || showDeleted) &&
                    !expanded.includes(node.id) ? (
                    <StyledTreeIconButton
                      sx={{ padding: 1.25 }}
                      mode={localTheme}
                      title={`Expand ${node.name}`}
                      data-cy="tree-expand-icon"
                      onClick={event => {
                        setSelectedNode(node.id);
                        event.stopPropagation(); // Prevent selection
                        onOpenClick();
                      }}
                      icon={<ChevronRight />}
                    />
                  ) : (
                    node.hasChildren &&
                    (!deletedChildren || showDeleted) &&
                    expanded.includes(node.id) && (
                      <StyledTreeIconButton
                        sx={{ padding: 1.25 }}
                        mode={localTheme}
                        data-cy="tree-collapse-icon"
                        title={`Collapse ${node.name}`}
                        onClick={event => {
                          setSelectedNode(node.id);
                          event.stopPropagation();
                          setExpanded(curr =>
                            curr.filter(nodes => nodes !== node.id)
                          );
                        }}
                        icon={<ExpandMore />}
                      />
                    )
                  )}
                </Grid2>
              </Grid2>
            ),
          }}
          color={theme => deletedStyleColor(theme)}
          textWeight={400}
        >
          {!isEmpty(node.children)
            ? node.children
                .filter(deletedAsset =>
                  showDeleted ? deletedAsset : deletedAsset.deletedAt === null
                )
                .map(child => {
                  if (Array.isArray(child)) {
                    return child.reduce((allChildren, grandChild) => {
                      if (!showDeleted && grandChild?.deletedAt === null) {
                        return (
                          <TreeItems
                            isHierarchy={isHierarchy}
                            node={grandChild}
                            key={grandChild.id}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            selectedNode={selectedNode}
                            setSelectedNode={setSelectedNode}
                          />
                        );
                      }
                      return allChildren;
                    }, {});
                  }
                  return (
                    <TreeItems
                      isHierarchy={isHierarchy}
                      node={child}
                      key={child.id}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      selectedNode={selectedNode}
                      setSelectedNode={setSelectedNode}
                    />
                  );
                })
            : null}
        </AssetNavigationTreeItem>
        <Dialog
          fullWidth
          maxWidth="sm"
          aria-label="duplicate-asset-dialog"
          open={isDuplicateAssetOpen}
        >
          <DialogContent>
            <DuplicateAsset
              setOpenDuplicateAsset={setIsDuplicateAssetOpen}
              assetId={node.id}
              parentId={node.parent}
              getAsset={getAsset}
              isDuplicateAssetOpen={isDuplicateAssetOpen}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          aria-label="rename-asset-dialog"
          open={openEditingForm}
        >
          <DialogContent>
            <EditAsset
              asset={node}
              setAsset={setAsset}
              setAssetError={setError}
              loading={false}
              aria-label="editForm"
              setOpenEditingForm={setOpenEditingForm}
            />
          </DialogContent>
        </Dialog>
        <WarningDialog
          open={displayWarning?.open}
          handleAction={displayWarning.handleAccept}
          handleClose={displayWarning.handleClose}
          title={displayWarning?.title}
          body={displayWarning.body}
          secondaryButtonText={displayWarning?.secondaryButtonText}
          primaryButtonText={displayWarning?.primaryButtonText}
        />
      </>
    )
  );
};
TreeItems.propTypes = {
  node: PT.shape({
    assetSubType: PT.shape({}),
    id: PT.string,
    name: PT.string,
    children: PT.array,
    deletedAt: PT.string,
    hasChildren: PT.bool,
    checked: PT.bool,
    parent: PT.string,
    parentChecked: PT.bool,
    isTypeOnly: PT.bool,
    setInfo: PT.arrayOf(PT.shape({ maxCount: PT.number })),
    assetTypeCount: PT.number || PT.null,
    multipleSubtypes: PT.bool,
    assetTypeName: PT.string,
  }),

  expanded: PT.arrayOf(string),
  isHierarchy: PT.bool.isRequired,
  setExpanded: PT.func.isRequired,
  setSelectedNode: PT.func,
  selectedNode: PT.string,
};
TreeItems.defaultProps = {
  node: {},
  expanded: [],
  selectedNode: '',
  setSelectedNode: () => {},
};
export default TreeItems;

import { Grid2 } from '@mui/material';
import { styled, darken } from '@mui/material/styles';

export const StyledGrid = styled(Grid2)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    minWidth: 250,
    maxWidth: 250,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 270,
    maxWidth: 270,
  },
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.background.paper,
  ...theme.applyStyles('dark', {
    backgroundColor: darken(theme.palette.secondary.main, 0.9),
  }),
}));

export const StyledScrolledGrid = styled(Grid2)(() => ({
  overflowY: 'auto',
  height: '50vh',
}));

import { Grid2, useMediaQuery } from '@mui/material';
import ContactOnboarding from 'components/contact-onboarding';
import ImageSection from './image-section';
import { StyledTitleTypography, StyledDivider } from './styles';

const DdbPreview = () => {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const sections = [
    {
      title: 'Structured Project Assets',
      description:
        'Project data is stored in a consistent format across projects to simplify data creation and enable repeatable processes.',
      imageSrc: '/illustrations/landing-page/structuredProjectAssets.svg',
      imageAlt: 'Structured Project Assets',
    },
    {
      title: 'Design Parameters',
      description:
        "Design values from all disciplines are transparent and easily accessible. Each parameter links to it's source and tracks the Check & Approval status.",
      imageSrc: '/illustrations/landing-page/DesignParameters.svg',
      imageAlt: 'Design Parameters',
      imageRight: true,
    },
    {
      title: 'Project History',
      description:
        'DDB tracks all changes on the project, giving you a complete picture of how your project has evolved over time.',
      imageSrc: '/illustrations/landing-page/ProjectHistory.svg',
      imageAlt: 'Project History',
    },
  ];

  return (
    <Grid2
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      paddingBottom={theme => theme.spacing(8)}
    >
      <Grid2 item size={12}>
        <StyledTitleTypography
          variant={isMdUp ? 'h3' : 'h1'}
          component="h1"
          color="inherit"
        >
          Preview of DDB Platform
        </StyledTitleTypography>
      </Grid2>
      <Grid2 item size={12} paddingTop={theme => theme.spacing(4)}>
        <StyledDivider />
      </Grid2>
      {sections.map((section, index) => (
        <div key={section.title}>
          <ImageSection
            title={section.title}
            description={section.description}
            imageSrc={section.imageSrc}
            imageAlt={section.imageAlt}
            imageRight={section.imageRight}
            isMdUp={isMdUp}
          />
          {index < sections.length - 1 && <StyledDivider />}
        </div>
      ))}
      <ContactOnboarding isMdUp={isMdUp} />
    </Grid2>
  );
};

export default DdbPreview;

import { useContext } from 'react';
import { Drawer, Toolbar, Divider, Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useParams, useNavigate, useSearchParams } from 'react-router';
import { SourcesContext } from 'context';
import Alert from 'components/alerts';
import SourcesHeader from './sources-header';
import SourcesForm from './sources-form';
import LinkedParameters from './linked-parameters';

function SourcesPanel() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const {
    selectedSource,
    openEditingForm,
    sourceErrorOrSuccess,
    setSourceErrorOrSuccess,
    isLoading,
  } = useContext(SourcesContext);

  return (
    <Drawer
      aria-label="source-detail-panel"
      variant="persistent"
      sx={{
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: '32vw',
          boxSizing: 'border-box',
        },
      }}
      open={
        (!isLoading &&
          isEmpty(selectedSource) &&
          !isEmpty(sourceErrorOrSuccess)) ||
        !isEmpty(selectedSource)
      }
      anchor="right"
    >
      <Toolbar />
      <SourcesHeader />
      <Divider />
      {!isEmpty(sourceErrorOrSuccess) && (
        <Box
          sx={theme => ({
            padding: theme.spacing(3),
          })}
          aria-label="source-error"
        >
          <Alert
            onClose={() => {
              setSourceErrorOrSuccess({});
              navigate({
                pathname: `/projects/${projectId}/sources`,
                search: searchParams.toString(),
              });
            }}
            title={sourceErrorOrSuccess.msg}
            text={sourceErrorOrSuccess.details}
            severity={sourceErrorOrSuccess?.severity || 'error'}
          />
        </Box>
      )}
      {!isEmpty(selectedSource) && (
        <div>
          <SourcesForm />
          <Divider />
          {!openEditingForm && <LinkedParameters />}
        </div>
      )}
    </Drawer>
  );
}

export default SourcesPanel;

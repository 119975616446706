import { Suspense } from 'react';
import { Link as RouterLink } from 'react-router';
import { AllInbox } from '@mui/icons-material';
import {
  Skeleton,
  Link,
  Grid2,
  Container,
  Divider,
  Box,
  Stack,
} from '@mui/material';
import Resources from './resources';
import Community from './community';
import Release from './release';
import Legal from './legal';

function AppFooter() {
  return (
    <>
      <Divider />
      <Box
        width="100%"
        sx={{
          backgroundColor: theme => {
            if (theme.palette.mode !== 'dark') return theme.palette.grey[100];
            return theme.palette.grey[900];
          },
        }}
      >
        <Container
          maxWidth="md"
          width="100%"
          sx={{
            padding: '1rem',
          }}
        >
          <footer>
            <Grid2 container>
              <Grid2
                item
                size={{ xs: 12, sm: 4 }}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  paddingBottom={theme => theme.spacing(4)}
                >
                  <Box
                    sx={theme => ({
                      display: 'flex',
                      alignItems: 'center',
                      bgcolor: 'primary.main',
                      borderRadius: '20%',
                      padding: theme.spacing(1),
                    })}
                  >
                    <AllInbox
                      fontSize="large"
                      sx={theme => ({
                        color: theme.palette.background.paper,
                      })}
                    />
                  </Box>
                  <Link
                    underline="none"
                    component={RouterLink}
                    variant="body1"
                    fontWeight="medium"
                    color="primary"
                    to="/"
                  >
                    Digital Design Brief
                  </Link>
                </Stack>
              </Grid2>
              <Grid2 item size={8}>
                <Grid2 container>
                  <Grid2
                    item
                    size={{ xs: 12, sm: 4 }}
                    sx={theme => ({
                      marginBottom: theme.spacing(6),
                      '& ul': {
                        margin: 0,
                        padding: 0,
                        listStyle: 'none',
                      },
                      '& li': {
                        padding: theme.spacing(0.75),
                        paddingLeft: 0,
                      },
                    })}
                  >
                    <Suspense fallback={<Skeleton width={60} height={150} />}>
                      <Community />
                    </Suspense>
                  </Grid2>
                  <Grid2
                    item
                    size={{ xs: 12, sm: 4 }}
                    sx={theme => ({
                      marginBottom: theme.spacing(6),
                      '& ul': {
                        margin: 0,
                        padding: 0,
                        listStyle: 'none',
                      },
                      '& li': {
                        padding: theme.spacing(0.75),
                        paddingLeft: 0,
                      },
                    })}
                  >
                    <Suspense fallback={<Skeleton width={60} height={150} />}>
                      <Resources />
                    </Suspense>
                  </Grid2>
                  <Grid2
                    item
                    size={{ xs: 12, sm: 4 }}
                    sx={theme => ({
                      marginBottom: theme.spacing(6),
                      '& ul': {
                        margin: 0,
                        padding: 0,
                        listStyle: 'none',
                      },
                      '& li': {
                        padding: theme.spacing(0.75),
                        paddingLeft: 0,
                      },
                    })}
                  >
                    <Suspense fallback={<Skeleton width={60} height={150} />}>
                      <Legal />
                    </Suspense>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
            <Suspense fallback={<Skeleton />}>
              <Release />
            </Suspense>
          </footer>
        </Container>
      </Box>
    </>
  );
}

export default AppFooter;

import { Box, Grid2, useMediaQuery, Button, Skeleton } from '@mui/material';
import { isEmpty } from 'lodash';
import CookiesHeader from 'routes/home/cookies/cookies-header';
import CookiesItems from 'routes/home/cookies/cookies-items';
import useGetCookies from './hooks/use-get-cookies';

function Cookies() {
  const screenMd = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const { cookieTypes, onAcceptSelectedCookiesClick, cookiesClean } =
    useGetCookies();

  return (
    <Box width={screenMd ? '90%' : '60%'} margin={theme => theme.spacing(5)}>
      <Grid2 container>
        <CookiesHeader />
      </Grid2>
      <Grid2 container marginTop={theme => theme.spacing(3)}>
        {isEmpty(cookieTypes) ? (
          <Skeleton />
        ) : (
          cookieTypes.map(type => {
            return <CookiesItems key={type.type} cookieList={type} />;
          })
        )}
      </Grid2>
      <Grid2 container justifyContent="flex-end">
        <Button
          sx={theme => ({
            width: '40%',
            margin: theme.spacing(1),
          })}
          aria-label="accept-selected-cookie-button"
          variant="contained"
          onClick={onAcceptSelectedCookiesClick}
          disabled={cookiesClean}
          data-cy="cookies-accept-button"
        >
          Save Changes
        </Button>
      </Grid2>
    </Box>
  );
}
export default Cookies;

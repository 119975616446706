import { useContext } from 'react';
import { Work } from '@mui/icons-material';
import { CardHeader, Typography, useMediaQuery } from '@mui/material';
import { ProjectContext } from 'context';

const Header = () => {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { project } = useContext(ProjectContext);

  return (
    <CardHeader
      aria-label="project-details-header"
      title={
        <Typography variant="h4" component="h2" aria-label="job-name">
          {project.shortTitle}
        </Typography>
      }
      avatar={
        <Work
          sx={[
            screenSm
              ? {
                  fontSize: 30,
                }
              : {
                  fontSize: 50,
                },
          ]}
          status="project"
        />
      }
      slotProps={{
        title: { color: theme => theme.palette.secondary },
      }}
    />
  );
};

export default Header;

import PT from 'prop-types';
import { useContext, useEffect, useState, Suspense } from 'react';
import { ArrowDownward, ArrowUpward, AddBox } from '@mui/icons-material';
import qs from 'qs';
import {
  Grid2,
  Divider,
  Badge,
  Button,
  Typography,
  Chip,
  Skeleton,
  Tooltip,
} from '@mui/material';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from 'react-router';
import { isEmpty } from 'lodash';
import {
  ProjectPermissionContext,
  AssetContext,
  DataTemplatesProvider,
  ParametersContext,
  ProjectContext,
} from 'context';
import HeaderBreadcrumbs from 'routes/parameters-page/common/header-breadcrumb-tree/headerBreadcrumbs';
import useDrawerHeaders from 'routes/parameters-page/asset-details-pane/header/hooks/use-drawer-headers';
import AddParameters from '../../parameters-wizard';

function Header({ handleFilterActive, filterCount, setFilterCount }) {
  const { isLoading, theAsset, setTheAsset } = useContext(AssetContext);
  const { project } = useContext(ProjectContext);
  const { parameters, loading } = useContext(ParametersContext);
  const { projectId, assetId, parameterId } = useParams();
  const navigate = useNavigate();
  const [parameterWizardOpen, setParameterWizardOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const toggleParameterWizard = () =>
    setParameterWizardOpen(!parameterWizardOpen);
  const { writePermission } = useContext(ProjectPermissionContext);
  const { ...parsedSearch } = qs.parse(searchParams.toString());
  const { assetList, assetsLoading } = useDrawerHeaders(theAsset, setTheAsset);

  useEffect(() => {
    if (!isEmpty(parsedSearch) && setFilterCount) {
      // eslint-disable-next-line camelcase, no-unused-vars
      const { sort_by, order, ...filtersOnly } = { ...parsedSearch };
      let filtersLength = 0;
      if (filtersOnly.show_deleted_parameters === 'true') {
        filtersLength += 1;
      }
      delete filtersOnly.show_deleted_parameters;
      delete filtersOnly.project_parameter;
      delete filtersOnly.show_deleted_assets;
      filtersLength += Object.values(filtersOnly).flatMap(num => num).length;
      setFilterCount(filtersLength);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedSearch]);

  const handleSortDirection = () => {
    const search = {
      ...parsedSearch,
      order: parsedSearch.order === 'asc' ? 'desc' : 'asc',
    };
    if (parameterId)
      navigate({
        pathname: assetId
          ? `/projects/${projectId}/asset/${assetId}/parameters`
          : `/projects/${projectId}/parameters`,

        search: `?${createSearchParams(search)}`,
      });
    else setSearchParams(search);
  };

  const disabled = !!(
    !(parsedSearch.project_parameter || !isEmpty(theAsset)) ||
    theAsset?.deletedAt ||
    isLoading ||
    (loading && isEmpty(parameters))
  );

  const renderToolTip = () => {
    if (!writePermission) return "You don't have permission to do this";
    if (theAsset?.deletedAt) return 'Cannot add parameters to deleted asset.';
    if (disabled) return 'Please select an asset';
    return 'Add parameters';
  };

  return (
    <>
      <Grid2
        container
        justifyContent="flex-start"
        alignItems="center"
        data-testid="parameterListHeader"
        id="paramListHeader"
        overflow="none"
        sx={{
          marginLeft: theme => theme.spacing(4),
        }}
      >
        {isLoading && <Skeleton width="90%" height={100} />}
        {!isLoading && (
          <Grid2
            container
            alignItems="center"
            justifyContent="space-between"
            width="95%"
          >
            <Grid2 item>
              <Typography
                variant="h3"
                sx={{ margin: theme => theme.spacing(1) }}
              >
                {theAsset?.name || project?.shortTitle}
              </Typography>
            </Grid2>
            <Grid2 item sx={{ flexGrow: 1 }}>
              <Chip
                variant="outlined"
                sx={{
                  backgroundColor: theme => theme.palette.background.paper,
                  margin: theme => theme.spacing(0.5),
                  padding: theme => theme.spacing(1),
                  borderRadius: theme => theme.spacing(1), // Make it squarer
                  fontWeight: 'bold', // Bold text
                  color: theme => theme.palette.primary.main, // Contrast text color
                  fontSize: '1rem', // Larger text
                }}
                label={theAsset?.assetType?.name || 'Project'}
              />
            </Grid2>
            <Grid2
              item
              sx={{
                marginRight: theme => theme.spacing(12),
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <HeaderBreadcrumbs
                assetsLoading={assetsLoading}
                assetList={assetList}
              />
            </Grid2>
          </Grid2>
        )}
        <Grid2 item size={12}>
          <Divider />
        </Grid2>
        <Grid2
          item
          sx={{
            margin: theme => theme.spacing(1),
            marginTop: theme => theme.spacing(2),
          }}
        >
          <Tooltip title={renderToolTip()}>
            <span>
              <Button
                variant="contained"
                icon={<AddBox />}
                disabled={disabled}
                onClick={toggleParameterWizard}
                data-cy={'add-button-parameter'}
                type="parameter"
              >
                Add Parameters
              </Button>
            </span>
          </Tooltip>
        </Grid2>
        {parameters.length > 0 && (
          <>
            <Grid2
              item
              sx={{
                margin: theme => theme.spacing(1),
                marginTop: theme => theme.spacing(2),
              }}
            >
              <Tooltip
                title={
                  filterCount
                    ? `${filterCount} filters applied`
                    : 'Filter and Sort'
                }
              >
                <Badge
                  aria-label="filters-badge"
                  color="error"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  badgeContent={filterCount}
                  overlap="circular"
                  sx={{ '& .MuiBadge-badge': { right: 0 } }}
                >
                  <Button
                    onClick={handleFilterActive}
                    variant="contained"
                    aria-label="Filter and Sort"
                  >
                    Filter and Sort
                  </Button>
                </Badge>
              </Tooltip>
            </Grid2>
            <Grid2
              item
              sx={{
                margin: theme => theme.spacing(1),
                marginTop: theme => theme.spacing(2),
              }}
            >
              <Tooltip title="Sort direction">
                <Button
                  variant="contained"
                  onClick={handleSortDirection}
                  aria-label="Sort direction"
                >
                  {parsedSearch.order === 'asc' ? (
                    <ArrowUpward data-testid="asc" />
                  ) : (
                    <ArrowDownward data-testid="desc" />
                  )}
                  Sort direction
                </Button>
              </Tooltip>
            </Grid2>
          </>
        )}
      </Grid2>
      {parameterWizardOpen && (
        <Suspense fallback={null}>
          <DataTemplatesProvider>
            <AddParameters
              open={parameterWizardOpen}
              handleToggleOpen={toggleParameterWizard}
            />
          </DataTemplatesProvider>
        </Suspense>
      )}
    </>
  );
}

Header.propTypes = {
  handleFilterActive: PT.func.isRequired,
  filterCount: PT.number,
  setFilterCount: PT.func,
};

Header.defaultProps = {
  filterCount: 0,
  setFilterCount: () => {},
};

export default Header;

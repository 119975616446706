import { Button } from '@mui/material';
import PT from 'prop-types';

const Actions = ({ wizard, actionButtons }) => {
  return (
    <>
      {actionButtons[wizard].map(
        ({ onClick, button, dataCy, disabled, sx, type, ...rest }) => {
          return type !== 'finish' ? (
            <Button
              key={button}
              data-cy={dataCy}
              onClick={onClick}
              disabled={disabled}
              sx={sx}
            >
              {button}
            </Button>
          ) : (
            <Button
              key={button}
              data-cy={dataCy}
              onClick={onClick}
              disabled={disabled}
              variant={rest.variant}
              color={rest.color}
              loading={rest.loading}
              loadingPosition={rest.loadingPosition}
              startIcon={rest.startIcon}
            >
              {button}
            </Button>
          );
        }
      )}
    </>
  );
};

Actions.propTypes = {
  errors: PT.shape({ parameterTypes: PT.arrayOf(PT.shape) }),
  wizard: PT.string.isRequired,
  actionButtons: PT.objectOf(
    PT.arrayOf(PT.shape({ type: PT.string, disabled: PT.bool }))
  ).isRequired,
};
Actions.defaultProps = {
  errors: { parameterTypes: [] },
};

export default Actions;

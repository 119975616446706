import PT from 'prop-types';
import { useLocation } from 'react-router';
import qs from 'qs';
import { Grid2 } from '@mui/material';
import { SortDropdown } from 'components/pickers';

function SortByDropdown({
  handleSortDirection,
  handleSortOptions,
  sortByOptions,
  loading,
}) {
  const { search } = useLocation();
  const { order, sort_by: sortBy } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  return (
    <Grid2 item xs>
      <SortDropdown
        aria-label="sortDropdown"
        sx={{ width: 200 }}
        data-cy="sortDropdown"
        data-testid="sortDropdown"
        options={sortByOptions}
        disableClearable
        value={sortByOptions?.filter(item => item.value === sortBy)[0] || null}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(opt, val) => opt.value === val.value}
        onChange={handleSortOptions}
        disabled={loading}
        handleSortDirection={handleSortDirection}
        sortDirection={order || 'desc'}
      />
    </Grid2>
  );
}

SortByDropdown.propTypes = {
  handleSortDirection: PT.func.isRequired,
  handleSortOptions: PT.func.isRequired,

  sortByOptions: PT.arrayOf(
    PT.shape({ label: PT.string.isRequired, value: PT.string.isRequired })
  ).isRequired,

  loading: PT.bool.isRequired,
};

export default SortByDropdown;

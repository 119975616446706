import PT from 'prop-types';
import { Box, Typography } from '@mui/material';

function EmptyState({ Icon, title, subtitle }) {
  return (
    <Box
      sx={theme => ({
        textAlign: 'center',
        paddingTop: theme.spacing(5),
      })}
      data-testid="emptyState"
      aria-label="emptyState"
    >
      <Icon color="disabled" sx={{ fontSize: '4vw' }} />
      {title && (
        <Typography
          sx={theme => ({
            marginBottom: theme.spacing(1),
            fontSize: theme.typography.pxToRem(18),
            fontWeight: theme.typography.fontWeightMedium,
          })}
        >
          {title}
        </Typography>
      )}
      <Typography
        sx={theme => ({
          marginBottom: theme.spacing(1),
          fontSize: theme.typography.pxToRem(13),
        })}
      >
        {subtitle && subtitle}
      </Typography>
    </Box>
  );
}

EmptyState.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  Icon: PT.any.isRequired,
  title: PT.string,
  subtitle: PT.string,
};

EmptyState.defaultProps = {
  title: '',
  subtitle: '',
};

export default EmptyState;

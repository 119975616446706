import { useContext } from 'react';
import PT from 'prop-types';
import { Skeleton, Grid2 } from '@mui/material';
import { ProjectContext } from 'context';
import StyledTypography from './projectCard-styles';

const ProjectCard = ({ open }) => {
  const { project } = useContext(ProjectContext);

  return (
    <Grid2 container p={0.5} alignItems="center" aria-label="project-card">
      {open && (
        <Grid2 item size={10} paddingLeft={1}>
          <StyledTypography
            aria-label="projectName"
            align="left"
            variant="body2"
            component="h2"
          >
            {project?.shortTitle || <Skeleton height={40} sx={{ pl: 20 }} />}
          </StyledTypography>
          <StyledTypography
            color="textSecondary"
            variant="body2"
            aria-label="projectNumber"
          >
            {project?.number || <Skeleton height={20} sx={{ pl: 20 }} />}
          </StyledTypography>
        </Grid2>
      )}
    </Grid2>
  );
};
ProjectCard.propTypes = {
  open: PT.bool.isRequired,
};
export default ProjectCard;

import { Grid2, useMediaQuery } from '@mui/material';
import PT from 'prop-types';
import ExpandButton from 'components/drawer/permanent/expand-button';
import {
  FoldedStyledTypography,
  ExpandedStyledTypography,
} from './folding-header-styles';

const FoldingHeader = ({
  title,
  open,
  onClick,
  disabled,
  collapsedTooltip,
}) => {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  return (
    <>
      {open && (
        <Grid2
          container
          justifyContent="space-between"
          alignItems="center"
          sx={[
            theme => ({
              paddingTop: theme.spacing(0),
            }),
            title
              ? {
                  height: theme => theme.spacing(7.5),
                }
              : {
                  height: theme => theme.spacing(2.5),
                },
          ]}
        >
          <Grid2 item size={{ xs: 9, sm: 10, md: 10, lg: 11, xl: 11 }}>
            <ExpandedStyledTypography
              aria-label="expanded-drawer-title"
              variant={screenSm ? 'h6' : 'h5'}
              component="h1"
            >
              {title}
            </ExpandedStyledTypography>
          </Grid2>
          <Grid2 item size={{ xs: 3, sm: 2, md: 2, lg: 1, xl: 1 }}>
            <Grid2 container justifyContent="flex-end">
              <ExpandButton
                disabled={disabled}
                open={open}
                toggleDrawerOpen={onClick}
                title={title}
              />
            </Grid2>
          </Grid2>
        </Grid2>
      )}
      {!open && (title || collapsedTooltip) && (
        <Grid2
          container
          justifyContent="center"
          sx={theme => ({
            paddingTop: theme.spacing(1.5),
          })}
        >
          <ExpandButton
            disabled={disabled}
            open={open}
            toggleDrawerOpen={onClick}
            title={title}
          />
          <Grid2 size={8} container justifyContent="center">
            <FoldedStyledTypography
              aria-label="folded-drawer-title"
              variant="body2"
            >
              {title || collapsedTooltip}
            </FoldedStyledTypography>
          </Grid2>
        </Grid2>
      )}
    </>
  );
};

FoldingHeader.propTypes = {
  title: PT.string.isRequired,
  collapsedTooltip: PT.string,
  open: PT.bool.isRequired,
  onClick: PT.func.isRequired,
  disabled: PT.bool,
};
FoldingHeader.defaultProps = {
  disabled: false,
  collapsedTooltip: null,
};
export default FoldingHeader;

import { Grid2 } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledGrid = styled(props => <Grid2 {...props} />)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(100px, auto))',
  gridTemplateAreas: "'parameterName asset' 'date value'",
}));

export default StyledGrid;

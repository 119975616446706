/* eslint-disable no-nested-ternary */
import PT from 'prop-types';
import { Done, DoneAll, SettingsBackupRestore } from '@mui/icons-material';
import { parameterType } from 'types';
import StyledIconButton from '../styled';
import useButton from '../../hooks/use-button';

function UpdateStatusButton({
  parameter,
  handleOpenResetForm,
  handleOpenApproveForm,
  handleOpenCheckForm,
}) {
  const { renderTooltipDoneIcon } = useButton();

  return (
    <StyledIconButton
      type="updateStatus"
      title={renderTooltipDoneIcon(parameter?.selectedEntry?.status)[0]}
      variant="outlined"
      onClick={() => {
        if (parameter?.selectedEntry?.status === 'answered')
          handleOpenCheckForm();
        if (parameter?.selectedEntry?.status === 'checked') {
          handleOpenApproveForm();
        }
        if (parameter?.selectedEntry?.status === 'rejected')
          handleOpenResetForm();
      }}
      disabled={renderTooltipDoneIcon(parameter?.selectedEntry?.status)[1]}
      icon={
        parameter?.selectedEntry?.status === 'answered' ? (
          <Done aria-label="Done icon" />
        ) : parameter?.selectedEntry?.status === 'rejected' ? (
          <SettingsBackupRestore aria-label="Reset icon" />
        ) : (
          <DoneAll aria-label="Done all icon" />
        )
      }
    />
  );
}

UpdateStatusButton.propTypes = {
  parameter: parameterType.isRequired,
  handleOpenResetForm: PT.func.isRequired,
  handleOpenApproveForm: PT.func.isRequired,
  handleOpenCheckForm: PT.func.isRequired,
};

export default UpdateStatusButton;

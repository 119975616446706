import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid2,
} from '@mui/material';
import useGetCookies from 'routes/user-settings/cookies/hooks/use-get-cookies';
import CookiesItems from '../cookies-items';
import CookiesHeader from '../cookies-header';

function CookieConsent() {
  const {
    openCookiesConsent,
    onAcceptAllCookiesClick,
    onManageCookiesClick,
    managePreferences,
    onAcceptSelectedCookiesClick,
    cookieTypes,
  } = useGetCookies();

  return (
    <Dialog
      open={openCookiesConsent}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        component="span"
        id="scroll-dialog-title"
        sx={theme => ({
          padding: theme.spacing(4),
        })}
      >
        <CookiesHeader />
      </DialogTitle>
      {managePreferences && (
        <DialogContent
          dividers
          sx={theme => ({
            padding: theme.spacing(4),
          })}
        >
          <DialogContentText
            component="span"
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {cookieTypes.map(type => {
              return <CookiesItems key={type.type} cookieList={type} />;
            })}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Grid2 container width="100%">
          <Grid2
            display="flex"
            justifyContent="flex-end"
            item
            size={12}
            sx={theme => ({
              marginTop: theme.spacing(1),
            })}
          >
            {!managePreferences && (
              <Button
                sx={theme => ({
                  width: '35%',
                  margin: theme.spacing(1),
                })}
                aria-label="manage-cookie-button"
                variant="contained"
                onClick={onManageCookiesClick}
              >
                Manage Preferences
              </Button>
            )}
            <Button
              sx={theme => ({
                width: '35%',
                margin: theme.spacing(1),
              })}
              aria-label="accept-all-cookie-button"
              variant="contained"
              onClick={onAcceptAllCookiesClick}
            >
              Accept All Cookies
            </Button>
            {managePreferences && (
              <Button
                sx={theme => ({
                  width: '40%',
                  margin: theme.spacing(1),
                })}
                aria-label="accept-selected-cookie-button"
                variant="contained"
                onClick={onAcceptSelectedCookiesClick}
              >
                Accept Selected Cookies
              </Button>
            )}
          </Grid2>
        </Grid2>
      </DialogActions>
    </Dialog>
  );
}
export default CookieConsent;

import {
  Grid2,
  Menu,
  Chip,
  Typography,
  ListItem,
  Button,
  useMediaQuery,
} from '@mui/material';
import { Tune as TuneIcon } from '@mui/icons-material';
import { lighten, darken } from '@mui/material/styles';
import { useContext } from 'react';
import { StyledIconButton } from 'components/buttons';
import { ThemeContext } from 'context';
import { handleFiltersMenu } from '../../hooks';

const FilterByMenu = () => {
  const {
    handleFilterClick,
    handleFilterClose,
    openFilterMenu,
    anchorEl,
    handleFilterOptions,
    filterOptions,
    clearFilters,
  } = handleFiltersMenu();

  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { localTheme } = useContext(ThemeContext);
  return (
    <Grid2 container p={1} alignItems="center">
      {!screenSm && (
        <Grid2 item pr={1}>
          <Typography
            color="textSecondary"
            sx={theme => ({
              padding: theme.spacing(0.2),
              color: theme.palette.text.secondary,
              fontSize: 15,
            })}
          >
            Filter by
          </Typography>
        </Grid2>
      )}
      <Grid2 item>
        {Object.values(filterOptions)
          .flat()
          .filter(option => {
            return option.isActive;
          })
          .map(option => {
            return (
              <Chip
                onDelete={event => {
                  handleFilterOptions(event, option);
                }}
                key={option.label}
                aria-label={option.ariaLabel}
                label={option.label}
                sx={[
                  theme => ({
                    margin: theme.spacing(0.25),
                    color: theme.palette.textSecondary,
                  }),
                  localTheme === 'dark'
                    ? {
                        backgroundColor: theme =>
                          darken(theme.palette.primary.main, 0.3),
                      }
                    : {
                        backgroundColor: theme =>
                          lighten(theme.palette.primary.main, 0.8),
                      },
                ]}
              />
            );
          })}
      </Grid2>
      <StyledIconButton
        title="Set and edit project filters"
        onClick={handleFilterClick}
        icon={<TuneIcon data-testid="filterButton" />}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openFilterMenu}
        onClose={handleFilterClose}
        data-testId="filterMenu"
        slotProps={{
          list: {
            'aria-labelledby': 'basic-button',
          },
        }}
      >
        {Object.keys(filterOptions).map(filterType => {
          return (
            <Grid2 key={filterType}>
              <ListItem key={filterType}>
                <Typography
                  color="textSecondary"
                  sx={theme => ({
                    padding: theme.spacing(0.2),
                    color: theme.palette.text.secondary,
                    fontSize: 16,
                  })}
                >
                  {`${filterType.toString()}`}
                </Typography>
              </ListItem>
              <Grid2 item sx={{ width: 200, pl: 1 }}>
                {filterOptions[filterType].map(option => {
                  return (
                    <Chip
                      key={option.label}
                      aria-label={option.ariaLabel}
                      label={option.label}
                      onClick={event => {
                        handleFilterOptions(event, option);
                        handleFilterClose();
                      }}
                      variant="outlined"
                      sx={theme => ({
                        margin: theme.spacing(0.25),
                        color: theme.palette.textSecondary,
                        backgroundColor: option.isActive
                          ? theme => lighten(theme.palette.primary.main, 0.8)
                          : 'transparent',
                        fontWeight: option.isActive ? '450' : null,
                      })}
                    />
                  );
                })}
              </Grid2>
            </Grid2>
          );
        })}
      </Menu>
      <Button
        sx={theme => ({
          float: 'right',
          fontSize: 15,
          color: theme.palette.text.secondary,
          textTransform: 'none',
          textDecoration: 'underline',
        })}
        disableElevation
        onClick={clearFilters}
      >
        Clear Filters
      </Button>
    </Grid2>
  );
};

export default FilterByMenu;

import PT from 'prop-types';
import { DatePicker as MuiRffDatePicker } from 'mui-rff';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { enGB } from 'date-fns/locale';

function DatePicker({
  label,
  required,
  name,
  size,
  format,
  helperText,
  ...rest
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <MuiRffDatePicker
        label={label}
        required={required || true}
        name={name}
        size={size}
        format={format}
        locale={enGB}
        helperText={helperText}
        minDate={new Date('1000-01-01')}
        maxDate={new Date('2500-01-01')}
        {...rest}
      />
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  label: PT.string.isRequired,
  required: PT.bool,
  name: PT.string.isRequired,
  size: PT.string,
  format: PT.string,
  helperText: PT.string,
};

DatePicker.defaultProps = {
  required: true,
  size: 'small',
  format: 'd MMM yyyy',
  helperText: '',
};

export default DatePicker;

import PT from 'prop-types';
import { Typography } from '@mui/material';

const SearchedResultMessage = ({ searchValue }) => {
  return (
    <Typography
      variant="h4"
      color="primary"
      sx={theme => ({
        paddingTop: theme.spacing(10),
        paddingRight: theme.spacing(10),
      })}
    >
      {`Showing results for "${searchValue}"`}
    </Typography>
  );
};

SearchedResultMessage.propTypes = {
  searchValue: PT.string.isRequired,
};

export default SearchedResultMessage;

/* eslint-disable no-unused-vars */
import { PeoplePicker as MsPeoplePicker } from '@microsoft/mgt-react';
import { styled, useTheme } from '@mui/material/styles';
import PT from 'prop-types';

const StyledPeoplePicker = styled(MsPeoplePicker)(({ theme }) => ({
  '--input-background-color': 'none',
  variants: [
    {
      props: ({ error }) => error,
      style: {
        '--input-background-color': theme.palette.error.dark,
      },
    },
  ],
}));

function PeoplePicker({ showMax, userType, ...rest }) {
  const theme = useTheme();
  return (
    <StyledPeoplePicker
      showMax={5}
      userType={userType}
      className={theme.palette.mode === 'dark' && 'mgt-dark'}
      {...rest}
    />
  );
}

PeoplePicker.propTypes = {
  showMax: PT.number,
  userType: PT.string,
};

PeoplePicker.defaultProps = {
  showMax: 5,
  userType: 'user',
};

export default PeoplePicker;

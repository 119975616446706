import { Suspense, useContext } from 'react';
import PT from 'prop-types';
import {
  Grid2,
  useMediaQuery,
  Skeleton,
  Tooltip,
  Typography,
  Button,
} from '@mui/material';
import { AddOutlined, Add } from '@mui/icons-material';
import Search from 'components/search';
import { UserContext } from 'context';
import AddProjectModal from './add-project-modal';
import { addProjectWizard, handleFiltersMenu } from './hooks';
import { FilterByMenu } from './filters';
import StyledGrid from './action-bar-styles';
import SortByDropdown from './filters/auto-completes';

function ActionBar({
  addProject,
  searchValue,
  handleSearch,
  loading,
  loadFavourites,
}) {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const screenMd = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const { user } = useContext(UserContext);

  const { open, toggleOpen } = addProjectWizard();
  const { handleSortDirection, handleSortOptions, sortByOptions } =
    handleFiltersMenu();

  return (
    <>
      <StyledGrid>
        <Suspense fallback={<Skeleton height={50} width={200} />}>
          {!loadFavourites && (
            <>
              <Grid2 item sx={{ gridArea: 'titlebar' }}>
                <Search
                  variant="standard"
                  data-testid="search"
                  searchValue={searchValue}
                  handleSearch={handleSearch}
                />
              </Grid2>
              <Grid2 item sx={{ gridArea: 'filter' }}>
                <FilterByMenu />
              </Grid2>
              {screenSm ? (
                <Grid2 item pl={1} sx={{ gridArea: 'order' }}>
                  <SortByDropdown
                    loading={loading}
                    handleSortDirection={handleSortDirection}
                    handleSortOptions={handleSortOptions}
                    sortByOptions={sortByOptions}
                  />
                </Grid2>
              ) : (
                <Grid2 item container alignItems="center" size={9}>
                  <Grid2 item pl={1} sx={{ gridArea: 'order' }}>
                    <Typography
                      color="textSecondary"
                      sx={theme => ({
                        padding: theme.spacing(0.2),
                        color: theme.palette.text.secondary,
                        fontSize: 15,
                      })}
                    >
                      Sort by
                    </Typography>
                  </Grid2>
                  <Grid2 item pl={1}>
                    <SortByDropdown
                      loading={loading}
                      handleSortDirection={handleSortDirection}
                      handleSortOptions={handleSortOptions}
                      sortByOptions={sortByOptions}
                    />
                  </Grid2>
                </Grid2>
              )}
            </>
          )}
          <Grid2
            alignContent="space-around"
            item
            container
            sx={{ gridArea: 'button' }}
          >
            <Tooltip
              title={
                user?.roles?.includes('App.Internal')
                  ? 'New project'
                  : "You don't have permission to do this"
              }
            >
              <span>
                <Button
                  size="small"
                  height="10"
                  color="primary"
                  variant="contained"
                  aria-label="New project"
                  onClick={toggleOpen}
                  data-testid="newProject"
                  data-cy="newProject"
                  disabled={!user?.roles?.includes('App.Internal')}
                >
                  {screenMd ? <Add /> : <AddOutlined />}
                  {!screenMd && (
                    <Typography variant="body1" p={1}>
                      New project
                    </Typography>
                  )}
                </Button>
              </span>
            </Tooltip>
          </Grid2>
        </Suspense>
      </StyledGrid>
      <Suspense fallback={null}>
        <AddProjectModal
          open={open}
          toggleOpen={toggleOpen}
          addProject={addProject}
        />
      </Suspense>
    </>
  );
}

ActionBar.propTypes = {
  addProject: PT.func.isRequired,
  handleSearch: PT.func.isRequired,
  searchValue: PT.string.isRequired,
  loading: PT.bool.isRequired,
  loadFavourites: PT.bool.isRequired,
};

export default ActionBar;

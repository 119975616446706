import { useState } from 'react';
import { isEmpty } from 'lodash';
import useButton from 'components/buttons/hooks/use-button';
import config from 'config';

const useTreeItemMenu = (
  handleDeleteClick,
  handleRestoreClick,
  setMenuOpen,
  moreVertVisible,
  setMoreVertVisible,
  setIsDuplicateAssetOpen,
  setOpenEditingForm,
  node,
  projectLevelButton
) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleMenuOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    setMoreVertVisible(true);
  };

  const handleMenuClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
    setMenuOpen(false);
    setMoreVertVisible(false);
  };

  const handleDelete = event => {
    handleMenuClose(event);
    handleDeleteClick();
  };

  const handleEdit = event => {
    handleMenuClose(event);
    setOpenEditingForm(true);
  };

  const handleRestore = event => {
    handleMenuClose(event);
    handleRestoreClick();
  };

  const handleDuplicateAssetClick = event => {
    handleMenuClose(event);
    setIsDuplicateAssetOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const {
    renderTooltipDeleteIcon,
    renderTooltipDuplicateButton,
    renderTooltipRestore,
    renderTooltipEditCloseIcon,
  } = useButton();

  const handleCopy = event => {
    event.stopPropagation();
    navigator.clipboard.writeText(node.id);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  };

  const menuItems = [
    { label: 'Copy Asset ID', action: 'copyId', onClick: handleCopy },
    {
      label: 'Rename Asset',
      action: 'renameAsset',
      onClick: handleEdit,
      disabled:
        !isEmpty(node.assetSubType) ||
        node.deletedAt ||
        renderTooltipEditCloseIcon(false, 'Asset')[1],
      tooltipTitle:
        !isEmpty(node.assetSubType) || node.deletedAt
          ? 'Not available'
          : renderTooltipEditCloseIcon(false, 'Asset')[0],
    },
  ];

  if (config?.featureFlags?.isDuplicateAssetsEnabled && !projectLevelButton) {
    menuItems.push({
      label: 'Duplicate Asset',
      action: 'duplicateAsset',
      disabled:
        node.deletedAt ||
        !isEmpty(node.assetSubType) ||
        renderTooltipDuplicateButton()[1],
      tooltipTitle:
        !isEmpty(node.assetSubType) || node.deletedAt
          ? 'Not available'
          : renderTooltipDuplicateButton()[0],
      onClick: handleDuplicateAssetClick,
    });
  }
  if (node.deletedAt && !projectLevelButton) {
    menuItems.push({
      label: 'Restore Asset',
      action: 'restoreAsset',
      onClick: handleRestore,
      disabled: renderTooltipRestore()[1],
      tooltipTitle: renderTooltipRestore()[0],
    });
  } else if (!node.deletedAt && !projectLevelButton) {
    menuItems.push({
      label: 'Delete Asset',
      action: 'deleteAsset',
      onClick: handleDelete,
      disabled: renderTooltipDeleteIcon()[1],
      tooltipTitle: renderTooltipDeleteIcon()[0],
    });
  }

  return {
    anchorEl,
    menuItems,
    handleMenuOpen,
    handleMenuClose,
    handleTooltipClose,
    tooltipOpen,
    setTooltipOpen,
    setAnchorEl,
    setMenuOpen,
    setMoreVertVisible,
    moreVertVisible,
    setOpenEditingForm,
    setIsDuplicateAssetOpen,
    handleEdit,
    handleDelete,
    handleRestore,
    handleDuplicateAssetClick,
    handleCopy,
  };
};

export default useTreeItemMenu;

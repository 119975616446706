import { Typography, Grid2, Link, useMediaQuery } from '@mui/material';
import { Cookie } from '@mui/icons-material';
import config from 'config';

function CookiesHeader() {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const headerText = [
    {
      link: false,
      key: 'We use essential cookies to make our site work. We also use non-essential cookies to help us make improvements by measuring how you use the site.',
    },
    {
      key: 'For more information about the cookies we use, see our ',
      link: (
        <Link
          aria-label="link-to-cookies"
          underline="always"
          color="primary"
          target="_blank"
          variant="body2"
          href={config.url.policiesCookies}
        >
          Cookie Policy
        </Link>
      ),
    },
    {
      link: false,
      key: 'You can edit your selection at any time in the cookie settings page, within User Settings.',
    },
  ];

  return (
    <Grid2 container aria-label="cookies-header">
      {!screenSm && (
        <Grid2 item size={1}>
          <Cookie fontSize="large" aria-label="cookie-icon" />
        </Grid2>
      )}
      <Grid2 item size={11}>
        <Typography component="h1" variant="h4">
          Our use of cookies
        </Typography>
      </Grid2>
      {headerText.map(text => (
        <Typography
          key={text.key}
          variant="body2"
          sx={theme => ({
            paddingTop: theme.spacing(1),
          })}
        >
          {text.key}
          {text.link && text.link}
        </Typography>
      ))}
    </Grid2>
  );
}

export default CookiesHeader;

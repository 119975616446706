import { styled } from '@mui/material/styles';

const contentShift = (theme, props) => ({
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: props.open ? props.drawerwidth : theme.spacing(7),
});

export default styled('div')(({ theme, ...props }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: theme.spacing(7),

  ...(props.screenmd === 'true' && contentShift(theme, props)),
}));

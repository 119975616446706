import PT from 'prop-types';
import { Skeleton, Grid2, ListItemButton, Tooltip } from '@mui/material';
import { formatDate, formatTitle } from 'utils';
import {
  StyledTypographyCardInfo,
  StyledListItem,
  StyledTypography,
  StyledListItemText,
} from '../card-styles';
import StyledGrid from './data-set-card-styles';

const DataSetCard = ({ row, loading, keyAsset, selected, handleClick }) => {
  const gridAreas = ['dataSetName', 'asset', 'date'];

  return (
    <StyledListItem role="presentation" key={row?.name}>
      <ListItemButton
        sx={{
          height: '100%',
          width: '100%',
        }}
        selected={selected}
        onClick={handleClick}
        data-cy="dataSetCard"
        aria-label="dataSetCard"
        disabled={row?.deletedAt !== null || loading}
      >
        {loading ? (
          <StyledGrid>
            {gridAreas.map(area => {
              return (
                <Grid2
                  item
                  key={area}
                  data-testid="loading"
                  sx={{ gridArea: area, minWidth: '200px' }}
                >
                  <Skeleton width="90%" />
                </Grid2>
              );
            })}
          </StyledGrid>
        ) : (
          <Tooltip
            placement="right"
            followCursor
            title={`${formatTitle(row?.name)}`}
          >
            <StyledListItemText data-cy="dataSetCardContent">
              <StyledGrid>
                <Grid2
                  item
                  aria-label="dataSetName"
                  sx={{
                    gridArea: 'dataSetName',
                    marginBottom: 'auto',
                  }}
                >
                  <StyledTypographyCardInfo noWrap>
                    {formatTitle(row?.name)}
                  </StyledTypographyCardInfo>
                </Grid2>

                {row?.createdAt && !loading && (
                  <Grid2
                    item
                    sx={{
                      gridArea: 'date',
                      marginTop: 'auto',
                      textAlign: 'left',
                    }}
                    aria-label="date"
                  >
                    <StyledTypography noWrap>
                      {formatDate(row?.createdAt, undefined)}
                    </StyledTypography>
                  </Grid2>
                )}
                {keyAsset?.name && !loading && (
                  <Grid2
                    item
                    sx={{
                      gridArea: 'value',
                      marginTop: 'auto',
                      textAlign: 'right',
                    }}
                    aria-label="keyAsset"
                  >
                    <StyledTypography noWrap>{keyAsset.name}</StyledTypography>
                  </Grid2>
                )}
              </StyledGrid>
            </StyledListItemText>
          </Tooltip>
        )}
      </ListItemButton>
    </StyledListItem>
  );
};

DataSetCard.propTypes = {
  row: PT.shape({
    id: PT.string,
    name: PT.string,
    createdAt: PT.string,
    deletedAt: PT.string,
  }),
  loading: PT.bool.isRequired,
  keyAsset: PT.shape({ name: PT.string }),
  selected: PT.bool,
  handleClick: PT.func,
};

DataSetCard.defaultProps = {
  keyAsset: { name: '' },
  selected: false,
  handleClick: () => {},
  row: { id: '', name: '', createdAt: '', deletedAt: '' },
};

export default DataSetCard;

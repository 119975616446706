import { Suspense, useContext } from 'react';
import { Divider, Grid2 } from '@mui/material';
import { FoldingDrawer } from 'components/drawer';
import PageLayoutBox from 'components/layout/page-layout-styles';
import NavigationHeader from 'components/cards/navigation-header';
import {
  AssetContext,
  PageLayoutContext,
  DataTemplatesProvider,
} from 'context';
import config from 'config';
import { StyledBox } from '../parameters-page-styles';
import AssetWizard from '../asset-wizard';
import Assets from './assets';
import useAssetNavigation from './hook/use-asset-navigation';
import Filter from './filter';
import Items from './items';

const AssetNavigation = () => {
  const { openDrawers } = useContext(PageLayoutContext);
  const { wizardOpen } = useContext(AssetContext);
  const {
    assetNodes,
    loading,
    isProjectAssetHierarchy,
    filterMenu,
    handleOpenCloseDrawer,
    handleOpenCloseWizard,
    expanded,
    setExpanded,
    drawerWidth,
  } = useAssetNavigation();

  return (
    <>
      <FoldingDrawer
        open={openDrawers.openNavigation}
        width={drawerWidth}
        collapsedTooltip="Asset Navigation"
        onClick={handleOpenCloseDrawer}
      >
        <PageLayoutBox>
          <StyledBox sx={{ overflowY: 'hidden' }} aria-label="assetNavigation">
            <Suspense fallback={null}>
              <NavigationHeader
                open={openDrawers.openNavigation}
                handleClick={handleOpenCloseWizard}
                label="parameters"
                hasProjectCard
              />
            </Suspense>
            <Divider
              sx={{
                opacity: '0.6',
              }}
            />
            {openDrawers.openNavigation && (
              <>
                <Divider />
                <Grid2 container>
                  {!config.featureFlags.isParameterListTableEnabled && (
                    <Grid2 item size={7}>
                      <Items />
                    </Grid2>
                  )}
                  <Grid2
                    item
                    size={{
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: config.featureFlags.isParameterListTableEnabled
                        ? 12
                        : 5,
                    }}
                  >
                    <Filter
                      menu={filterMenu}
                      setExpanded={setExpanded}
                      expanded={expanded}
                    />
                  </Grid2>
                </Grid2>
                <Assets
                  loading={loading}
                  assetNodes={assetNodes}
                  isProjectAssetHierarchy={isProjectAssetHierarchy}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              </>
            )}
          </StyledBox>
        </PageLayoutBox>
      </FoldingDrawer>
      {wizardOpen && (
        <Suspense fallback={null}>
          <DataTemplatesProvider>
            <AssetWizard open={wizardOpen} toggleOpen={handleOpenCloseWizard} />
          </DataTemplatesProvider>
        </Suspense>
      )}
    </>
  );
};

export default AssetNavigation;

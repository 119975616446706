import { Form, FormSpy } from 'react-final-form';
import { useState } from 'react';
import PT from 'prop-types';
import arrayMutators from 'final-form-arrays';
import {
  Paper,
  Grid2,
  Button,
  Typography,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { indexOf, isEmpty } from 'lodash';
import { StyledIconButton } from '../buttons';

function FinalForm({
  title,
  initialValues,
  formFields,
  validate,
  onClose,
  onSubmit,
  isLoading,
  conditions,
  submitButtonLabel,
  onChange,
  isPanel,
  isEditing,
  enableSubmitButton,
  selectedSourceId,
  subtitle,
  resetButton,
  cancelButton,
  additionalButton,
  buttonComponent,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submittingButton = () => {
    if (isSubmitting)
      return (
        <CircularProgress
          size={25}
          thickness={6}
          color="secondary"
          aria-label="submitting progress icon"
        />
      );
    return submitButtonLabel || 'Submit';
  };

  if (isLoading)
    return (
      <Paper style={{ padding: 16 }} elevation={2}>
        <Skeleton title="loading" width="100%" height={35} />
      </Paper>
    );
  return (
    <Form
      onSubmit={onSubmit}
      aria-label="form"
      initialValues={initialValues}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        errors,
      }) => {
        return (
          <form onSubmit={handleSubmit} noValidate aria-label="form">
            <Paper
              data-cy="form-component"
              sx={[
                theme => ({
                  padding: theme.spacing(2),
                  boxShadow: 'none',
                  maxHeight: isPanel && !isEditing ? '50vh' : 'auto',
                }),
                isPanel
                  ? {
                      overflowY: 'auto',
                    }
                  : {
                      overflowY: 'none',
                    },
              ]}
              elevation={3}
            >
              <Grid2 container>
                <Grid2 item size={11} style={{ marginTop: 16 }}>
                  <Typography variant="h6">{title}</Typography>
                </Grid2>
                <Grid2 item size={11} style={{ marginTop: 16 }}>
                  <Typography variant="subtitle1">{subtitle}</Typography>
                </Grid2>
                {!isPanel && (
                  <Grid2 item size={1}>
                    <StyledIconButton
                      data-cy="close"
                      aria-label="close"
                      title="Close"
                      icon={<CloseIcon />}
                      onClick={onClose}
                      disabled={submitting}
                    />
                  </Grid2>
                )}
              </Grid2>
              <Grid2
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                spacing={2}
              >
                {formFields.map((item, idx) => (
                  <Grid2 item size={{ xs: 12, md: item?.size }} key={idx}>
                    <div style={{ width: '10px', padding: 0 }}>
                      {item?.icon}
                    </div>
                    <div style={{ padding: 0 }}>{item?.field}</div>
                  </Grid2>
                ))}
                {onChange && <FormSpy onChange={onChange} />}
                {conditions &&
                  conditions
                    .filter(item => {
                      const answeredFields = Object.keys(values);
                      const answeredValues = Object.values(values);
                      if (item.type === 'filter') {
                        return (
                          values[item.when] &&
                          !item.is.some(
                            isAnswered =>
                              isAnswered ===
                              answeredValues[indexOf(answeredFields, item.when)]
                          )
                        );
                      }
                      return item.is.some(
                        isAnswered =>
                          isAnswered ===
                          answeredValues[indexOf(answeredFields, item.when)]
                      );
                    })
                    .map((item, idx) => {
                      return (
                        <Grid2 item size={{ xs: 12, md: item?.size }} key={idx}>
                          {item.field}
                        </Grid2>
                      );
                    })}
              </Grid2>
              {isEditing && (
                <Grid2
                  sx={theme => ({
                    marginTop: theme.spacing(1),
                  })}
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  spacing={2}
                >
                  {additionalButton && (
                    <Grid2 item size={4}>
                      {buttonComponent}
                    </Grid2>
                  )}
                  <Grid2
                    item
                    container
                    justifyContent="flex-end"
                    spacing={2}
                    size={additionalButton ? 8 : 12}
                  >
                    {cancelButton && (
                      <Grid2 item>
                        <Button
                          aria-label="cancel"
                          onClick={onClose}
                          disabled={submitting}
                        >
                          Cancel
                        </Button>
                      </Grid2>
                    )}
                    {resetButton && (
                      <Grid2 item>
                        <Button
                          aria-label="reset"
                          type="button"
                          variant="contained"
                          onClick={form.reset}
                          disabled={
                            submitting || (pristine && !selectedSourceId)
                          }
                        >
                          Reset
                        </Button>
                      </Grid2>
                    )}
                    <Grid2 item>
                      <Button
                        data-cy="form-submit-button"
                        aria-label="submit"
                        variant="contained"
                        disabled={
                          !enableSubmitButton &&
                          ((pristine && !selectedSourceId) || !isEmpty(errors))
                        }
                        color="primary"
                        type="submit"
                        onClick={() => {
                          setIsSubmitting(submitting);
                        }}
                      >
                        {submittingButton()}
                      </Button>
                    </Grid2>
                  </Grid2>
                </Grid2>
              )}
            </Paper>
          </form>
        );
      }}
    />
  );
}

FinalForm.propTypes = {
  title: PT.string || null,
  subtitle: PT.string || null,
  initialValues: PT.shape({}).isRequired,
  formFields: PT.arrayOf(PT.shape({ size: PT.number, field: PT.node }))
    .isRequired,
  validate: PT.func.isRequired,
  onClose: PT.func,
  onSubmit: PT.func.isRequired,
  isLoading: PT.bool,
  conditions: PT.arrayOf(
    PT.shape({
      size: PT.number,
      field: PT.node,
      when: PT.string.isRequired,
      is: PT.arrayOf(PT.string),
    })
  ),
  submitButtonLabel: PT.string,
  onChange: PT.func,
  isPanel: PT.bool,
  isEditing: PT.bool,
  enableSubmitButton: PT.bool,
  selectedSourceId: PT.string,
  resetButton: PT.bool,
  cancelButton: PT.bool,
  additionalButton: PT.bool,
  buttonComponent: PT.node,
};

FinalForm.defaultProps = {
  isLoading: false,
  resetButton: true,
  conditions: [],
  submitButtonLabel: '',
  onChange: () => {},
  onClose: () => {},
  isPanel: false,
  isEditing: true,
  title: null,
  subtitle: null,
  enableSubmitButton: false,
  selectedSourceId: '',
  cancelButton: false,
  additionalButton: false,
  buttonComponent: null,
};

export default FinalForm;

import PT from 'prop-types';
import { Grid2, Typography, Divider } from '@mui/material';
import { Suspense } from 'react';
import Highlighted from 'components/highlighted';
import { formatRelativeDate } from 'utils';

function ExpandPanel({ project, searchValue }) {
  const { projectManagerName, projectDirectorName, createdAt } = project;
  return (
    <Suspense fallback={null}>
      <Divider />
      <Grid2
        container
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        sx={theme => ({
          padding: theme.spacing(1),
        })}
      >
        <Typography gutterBottom variant="body2" color="textSecondary">
          <Highlighted
            text={`${'PD'}: ${projectDirectorName}`}
            highlight={searchValue}
          />
        </Typography>
        <Typography gutterBottom variant="body2" color="textSecondary">
          <Highlighted
            text={`${'PM'}: ${projectManagerName}`}
            highlight={searchValue}
          />
        </Typography>
        <Typography gutterBottom variant="body2" color="textSecondary">
          {`${'Created at'}: ${formatRelativeDate(createdAt, undefined)}`}
        </Typography>
      </Grid2>
    </Suspense>
  );
}
ExpandPanel.propTypes = {
  project: PT.shape({
    projectDirectorName: PT.string,
    projectManagerName: PT.string,
    createdAt: PT.string,
    updatedAt: PT.string,
  }),
  searchValue: PT.string.isRequired,
};

ExpandPanel.defaultProps = {
  project: {
    projectDirectorName: '',
    projectManagerName: '',
    createdAt: '',
    updatedAt: '',
  },
};

export default ExpandPanel;

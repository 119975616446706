import {
  Collapse,
  Box,
  Skeleton,
  Typography,
  Grid2,
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';
import { ChevronRight, ExpandMore, Circle } from '@mui/icons-material';
import { capitalize, isEmpty } from 'lodash';
import { useContext } from 'react';
import ExpandOrCollapseButton from 'components/buttons/icons/expand-or-collapse';
import { StyledIconButton } from 'components/buttons';
import { StyledTreeItem } from 'components/tree/tree-item/tree-item-styles';
import { DataTemplatesContext } from 'context';
import useSummary from '../hooks/use-summary';

function AssetTypesSummary() {
  const { loadingAssets, formattedAssets, loadingParameters, templateAssets } =
    useContext(DataTemplatesContext);
  const { openSummary, handleOpen } = useSummary();

  const summaryText = [
    { key: 'A new ', fontWeight: 'regular' },
    {
      key: 'data set ',
      fontWeight: 'bold',
    },
    {
      key: 'will create parameters using these ',
      fontWeight: 'regular',
    },
    {
      key: 'asset type instances:',
      fontWeight: 'bold',
    },
  ];
  const expandedNodes = templateAssets?.map(asset => asset.id);

  const renderTree = nodes => {
    return (
      <StyledTreeItem
        key={nodes.id}
        itemId={nodes.id}
        label={
          <>
            <Grid2 container spacing={0.5}>
              <Grid2 item>
                <Typography
                  variant="body2"
                  color="primary"
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    flexGrow: 1,
                  }}
                >
                  {nodes.assetType?.name
                    ? capitalize(nodes.assetType.name)
                    : capitalize(nodes.name)}
                </Typography>
              </Grid2>
              <Grid2 item>
                <Typography
                  color="primary"
                  variant={nodes.maxCount ? 'body2' : 'body1'}
                  fontWeight="bold"
                  aria-label="asset-type-name-tree"
                >
                  {nodes.maxCount ? `(${nodes.maxCount})` : `(${'∞'})`}
                </Typography>
              </Grid2>
            </Grid2>
            <List
              aria-label="asset-level-info"
              key={nodes.id}
              role="list"
              sx={{ pt: 0 }}
            >
              <ListItem
                sx={{
                  pt: 0,
                  pb: 0,
                  pl: 1,
                  pr: 0,
                  textTransform: 'none',
                }}
                key={nodes.id}
              >
                <ListItemText
                  primary={
                    <>
                      <Typography
                        variant="body2"
                        component="span"
                        fontWeight="medium"
                        color="textPrimary"
                        aria-label="asset-placeholder-name"
                      >
                        {`${'Name'}: `}
                      </Typography>
                      {nodes.assetSubType?.name
                        ? nodes.assetSubType.name
                        : nodes.name}
                    </>
                  }
                  secondary={
                    <>
                      <Typography
                        variant="body2"
                        component="span"
                        fontWeight="medium"
                        color="textPrimary"
                        aria-label="asset-placeholder-description"
                      >
                        {'Description: '}
                      </Typography>
                      {nodes.description}
                    </>
                  }
                  slotProps={{
                    primary: {
                      variant: 'body2',
                      color: 'textSecondary',
                    },

                    secondary: {
                      variant: 'body2',
                      color: 'textSecondary',
                    },
                  }}
                />
              </ListItem>
            </List>
          </>
        }
      >
        {!isEmpty(nodes.children)
          ? nodes.children.map(node => renderTree(node))
          : null}
      </StyledTreeItem>
    );
  };

  const renderAssetsWithParameters = asset => {
    if (!isEmpty(asset.parameters)) {
      return (
        <div key={asset.id}>
          <Typography
            variant="body2"
            aria-label="asset-type-name-parameters-list"
            sx={theme => ({
              marginLeft: theme.spacing(1.5),
              textTransform: 'capitalize',
            })}
          >{`${asset.assetType?.name ? asset.assetType.name : asset.name} (${
            asset.name
          }):`}</Typography>
          <List aria-label="parameters-list" key={asset.id} role="list">
            {asset.parameters.map(parameter => (
              <ListItem
                sx={theme => ({
                  pt: 0,
                  pb: 0,
                  pl: theme.spacing(3),
                })}
                aria-label="parameter-name"
                key={parameter.id}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '16px',
                  }}
                >
                  <Circle
                    sx={theme => ({
                      fontSize: '6px',

                      color: theme.palette.common.black,
                      ...theme.applyStyles('dark', {
                        color: theme.palette.common.white,
                      }),
                    })}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={parameter.parameterTypeName}
                  slotProps={{
                    primary: { variant: 'body2' },
                  }}
                />
              </ListItem>
            ))}
          </List>
          {!isEmpty(asset.children) &&
            asset.children.map(renderAssetsWithParameters)}
        </div>
      );
    }

    return (
      !isEmpty(asset.children) && asset.children.map(renderAssetsWithParameters)
    );
  };

  return (
    <Box aria-label="contentBox" width="auto">
      <ExpandOrCollapseButton
        buttonSize="small"
        open={openSummary}
        onClick={handleOpen}
        sx={theme => ({
          marginLeft: theme.spacing(-1.5),
        })}
      />
      <Typography variant="caption" display="inline" color="textSecondary">
        Summary
      </Typography>
      <Collapse in={openSummary}>
        <>
          {!isEmpty(formattedAssets) &&
            summaryText.map(text => (
              <Typography
                key={text.key}
                variant="body1"
                display="inline"
                fontWeight={text.fontWeight}
              >
                {text.key}
              </Typography>
            ))}
          {loadingAssets ? (
            <Skeleton
              height={30}
              width="100%"
              display="inline"
              aria-label="loading-assets"
            />
          ) : (
            <SimpleTreeView
              aria-label="asset-tree"
              sx={theme => ({
                paddingTop: theme.spacing(1.25),
                paddingBottom: theme.spacing(1.25),
              })}
              slots={{
                expandIcon: () => (
                  <StyledIconButton
                    buttonSize="small"
                    icon={<ChevronRight />}
                  />
                ),
                collapseIcon: () => (
                  <StyledIconButton buttonSize="small" icon={<ExpandMore />} />
                ),
              }}
              disableSelection
              defaultExpandedItems={[...expandedNodes]}
            >
              {!isEmpty(formattedAssets) &&
                renderTree(formattedAssets, 'assets')}
            </SimpleTreeView>
          )}
          {!isEmpty(formattedAssets) && (
            <Typography
              variant="body1"
              display="inline-block"
              sx={theme => ({
                marginBottom: theme.spacing(1),
              })}
            >
              This template will group the following parameters within the asset
              instances:
            </Typography>
          )}
          {loadingParameters ? (
            <Skeleton
              height={30}
              width="100%"
              display="inline"
              aria-label="loading-parameters"
            />
          ) : (
            renderAssetsWithParameters(formattedAssets)
          )}
        </>
      </Collapse>
    </Box>
  );
}

export default AssetTypesSummary;

import { forwardRef } from 'react';
import { Grid2, Typography, Link, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router';
import PT from 'prop-types';
import { StyledTitleTypography } from '../../routes/home/styles';

const ProjectsLink = forwardRef((props, ref) => (
  <Link to="/projects" ref={ref} {...props} component={RouterLink} />
));

ProjectsLink.displayName = 'ProjectsLink';

const getTitleVariant = (titleSection, isMdUp) => {
  if (!titleSection) {
    return 'h3';
  }
  return isMdUp ? 'h3' : 'h1';
};

const ImageInformationSection = ({
  isMdUp,
  title,
  description,
  secondaryDescription,
  imageSrc,
  imageAlt,
  link,
  linkText,
  buttonText,
  titleSection,
  leftSection,
}) => {
  return (
    <Grid2
      container
      direction={leftSection ? 'row-reverse' : 'row'}
      justifyContent="space-between"
      alignItems="center"
      sx={theme => ({
        paddingBottom: theme.spacing(8),
        paddingTop: theme.spacing(8),
      })}
    >
      <Grid2 item size={{ xs: 12, sm: 12, md: titleSection ? 7 : 6 }}>
        <StyledTitleTypography
          variant={getTitleVariant(titleSection, isMdUp)}
          component="h1"
          gutterBottom
        >
          {title}
        </StyledTitleTypography>
        <Typography
          variant={isMdUp ? 'subtitle1' : 'h6'}
          component="subtitle1"
          fontWeight={theme => theme.typography.fontWeightRegular}
        >
          {description}
        </Typography>
        {titleSection ? (
          <Grid2 container>
            <Grid2
              item
              sx={theme => ({
                paddingBottom: theme.spacing(4),
              })}
            >
              <Button
                component={ProjectsLink}
                sx={{ marginTop: 4 }}
                variant="contained"
                data-cy="go-to-projects"
                color="primary"
              >
                <Typography variant="body2">{buttonText}</Typography>
              </Button>
            </Grid2>
          </Grid2>
        ) : (
          <>
            {secondaryDescription && (
              <Box paddingTop={theme => theme.spacing(4)}>
                <Typography
                  variant={isMdUp ? 'subtitle1' : 'h6'}
                  component="subtitle1"
                  fontWeight={theme => theme.typography.fontWeightRegular}
                >
                  {secondaryDescription}
                  <Link href={link} target="_blank">
                    {linkText}
                  </Link>
                </Typography>
              </Box>
            )}
            {!secondaryDescription && link && (
              <Typography
                variant={isMdUp ? 'subtitle1' : 'h6'}
                component="subtitle1"
                fontWeight={theme => theme.typography.fontWeightRegular}
              >
                <Link href={link} target="_blank">
                  {linkText}
                </Link>
              </Typography>
            )}
          </>
        )}
      </Grid2>
      <Grid2
        item
        size={{ xs: 12, sm: 12, md: titleSection ? 5 : 6 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          src={imageSrc}
          alt={imageAlt}
          style={{ width: '80%', height: 'auto' }}
        />
      </Grid2>
    </Grid2>
  );
};

export default ImageInformationSection;

ImageInformationSection.propTypes = {
  title: PT.string.isRequired,
  description: PT.string.isRequired,
  secondaryDescription: PT.string,
  imageSrc: PT.string.isRequired,
  imageAlt: PT.string.isRequired,
  link: PT.string,
  linkText: PT.string,
  isMdUp: PT.bool.isRequired,
  titleSection: PT.bool,
  buttonText: PT.string,
  leftSection: PT.bool,
};

ImageInformationSection.defaultProps = {
  secondaryDescription: null,
  link: null,
  linkText: null,
  titleSection: false,
  buttonText: 'Learn More',
  leftSection: false,
};

import { Suspense } from 'react';
import { Divider } from '@mui/material';
import { Routes, Route, Outlet, Navigate } from 'react-router';
import { withAppInsights } from 'app/app-insights';
import Sidebar from 'components/sidebar';
import { AssetProvider, PageLayoutProvider, ParametersProvider } from 'context';
import PageLayout from 'components/layout';
import config from 'config';
import AssetNavigation from './asset-navigation';
import AssetDetails from './asset-details-pane';
import ParameterListTable from './parameter-list-table';
import ParameterList from './parameter-list';
import ProjectDetails from './project-details';
import Details from './parameter-details-pane';

function ParametersPage() {
  return (
    <main>
      <PageLayoutProvider>
        <PageLayout aria-label="page-layout">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Suspense fallback={null}>
                    <Sidebar id="parameters-nav" />
                  </Suspense>
                  <Suspense fallback={null}>
                    <AssetProvider>
                      <ParametersProvider>
                        <AssetNavigation />
                        <Outlet />
                      </ParametersProvider>
                    </AssetProvider>
                  </Suspense>
                </>
              }
            >
              {!config.featureFlags.isParameterListTableEnabled && (
                <Route
                  path="/"
                  element={
                    <Suspense fallback={null}>
                      <ParametersProvider>
                        <Divider orientation="vertical" flexItem />
                        <ProjectDetails />
                        <Outlet />
                      </ParametersProvider>
                    </Suspense>
                  }
                >
                  <Route
                    path="parameters"
                    element={
                      <Suspense fallback={null}>
                        <Divider orientation="vertical" flexItem />
                        <ParameterList />
                        <Outlet />
                      </Suspense>
                    }
                  >
                    <Route
                      path=":parameterId"
                      element={
                        <Suspense fallback={null}>
                          <Divider orientation="vertical" flexItem />
                          <Details />
                        </Suspense>
                      }
                    />
                    <Route
                      path="*"
                      element={<Navigate to="/error" replace />}
                    />
                  </Route>
                  <Route path="*" element={<Navigate to="/error" replace />} />
                </Route>
              )}
              {config.featureFlags.isParameterListTableEnabled && (
                <Route path="/">
                  <Route
                    path="parameters"
                    element={
                      <Suspense fallback={null}>
                        <Divider orientation="vertical" flexItem />
                        <ParameterListTable />
                        <Outlet />
                      </Suspense>
                    }
                  >
                    <Route
                      path=":parameterId"
                      element={
                        <Suspense fallback={null}>
                          <Divider orientation="vertical" flexItem />
                          <Details />
                        </Suspense>
                      }
                    />
                    <Route
                      path="*"
                      element={<Navigate to="/error" replace />}
                    />
                  </Route>
                  <Route path="*" element={<Navigate to="/error" replace />} />
                </Route>
              )}
              <Route path="asset">
                {!config.featureFlags.isParameterListTableEnabled && (
                  <Route
                    path=":assetId"
                    element={
                      <Suspense fallback={null}>
                        <ParametersProvider>
                          <Divider orientation="vertical" flexItem />
                          <AssetDetails />
                          <Outlet />
                        </ParametersProvider>
                      </Suspense>
                    }
                  >
                    <Route
                      path="parameters"
                      element={
                        <Suspense fallback={null}>
                          <Divider orientation="vertical" flexItem />
                          <ParameterList />
                          <Outlet />
                        </Suspense>
                      }
                    >
                      <Route
                        path=":parameterId"
                        element={
                          <Suspense fallback={null}>
                            <Divider orientation="vertical" flexItem />
                            <Details />
                          </Suspense>
                        }
                      />
                      <Route
                        path="*"
                        element={<Navigate to="/error" replace />}
                      />
                    </Route>
                    <Route
                      path="*"
                      element={<Navigate to="/error" replace />}
                    />
                  </Route>
                )}
                {config.featureFlags.isParameterListTableEnabled && (
                  <Route path=":assetId">
                    <Route
                      path="parameters"
                      element={
                        <Suspense fallback={null}>
                          <Divider orientation="vertical" flexItem />
                          <ParameterListTable />
                          <Outlet />
                        </Suspense>
                      }
                    >
                      <Route
                        path=":parameterId"
                        element={
                          <Suspense fallback={null}>
                            <Divider orientation="vertical" flexItem />
                            <Details />
                          </Suspense>
                        }
                      />
                      <Route
                        path="*"
                        element={<Navigate to="/error" replace />}
                      />
                    </Route>
                    <Route
                      path="*"
                      element={<Navigate to="/error" replace />}
                    />
                  </Route>
                )}
              </Route>
            </Route>
          </Routes>
        </PageLayout>
      </PageLayoutProvider>
    </main>
  );
}

export default withAppInsights(ParametersPage, 'ParametersPage');

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import PT from 'prop-types';
import { ChevronRight } from '@mui/icons-material';
import { useState } from 'react';
import AuditAccordionHeader from '../audit-accordion-header/audit-accordion-header';
import AuditAccordionDetails from '../audit-accordion-details/audit-accordion-details';
import { historyPropTypes } from '../history-content/prop-types/prop-types';

const AuditItemAccordion = ({ history, isLoaded }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (_, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      expanded={expanded}
      onChange={handleToggle}
      sx={theme => ({
        boxShadow: 'none',
        borderTop: `1px solid ${theme.palette.divider}`,
        '&::before': {
          display: 'none',
        },
        '& .css-1betqn-MuiAccordionSummary-content': {
          margin: `${theme.spacing(1.2)} 0`,
        },
      })}
    >
      <AccordionSummary
        expandIcon={<ChevronRight />}
        aria-label="accordion-summary"
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        }}
      >
        <AuditAccordionHeader isLoaded={isLoaded} history={history} />
      </AccordionSummary>
      <AccordionDetails aria-label="accordion-details">
        <AuditAccordionDetails history={history} expanded={expanded} />
      </AccordionDetails>
    </Accordion>
  );
};

AuditItemAccordion.propTypes = {
  isLoaded: PT.bool.isRequired,
  isLastAccordion: PT.bool.isRequired,
  history: historyPropTypes.isRequired,
};

export default AuditItemAccordion;

import { useContext, useState } from 'react';
import { Box } from '@mui/material';
import Alert from 'components/alerts';
import { AssetContext, GlobalTypesContext } from 'context';
import AddAssets from '../add-assets';
import Summary from '../summary';

export default toggleOpen => {
  const { setAssetTypeTrees } = useContext(GlobalTypesContext);
  const {
    handleError,
    setAssetManipulation,
    setIndex,
    setIsLoading,
    setTheAsset,
    postAssets,
    patchAssets,
    setAssetWizardColumns,
  } = useContext(AssetContext);

  const [error, setError] = useState(undefined);

  const openOrClose = () => {
    setError(undefined);
    toggleOpen();
    setAssetManipulation({
      isOpen: {},
      isSelected: {},
      isEdited: {},
      isAddingNew: {},
      hasSubTypes: {},
      allSubTypesUsed: {},
    });
  };

  const handleClose = () => {
    setError(undefined);
    setIndex(0);
    setTheAsset({});
    setAssetTypeTrees([]);
    setAssetWizardColumns([]);
    openOrClose();
    handleError();
  };

  const submit = async () => {
    setError(undefined);
    setIsLoading(true);
    try {
      await postAssets();
      await patchAssets();
      setIndex(0);
      setAssetWizardColumns([]);
      setAssetTypeTrees([]);
      setTheAsset({});
      openOrClose();
      setIsLoading(false);
    } catch (err) {
      setError(err?.response?.data);
      setIsLoading(false);
    }
  };

  const steps = [
    {
      label: 'Assets',
      optional: false,
      header: 'Add new assets or update existing ones',
      content: props => <AddAssets {...props} />,
    },
    {
      label: 'Summary',
      optional: false,
      header: 'Are these the assets you wish to add or update?',
      content: props => (
        <>
          {!!error && (
            <Box
              sx={theme => ({
                padding: theme.spacing(2, 0),
              })}
            >
              <Alert
                color="error"
                title={error?.msg || 'An error occurred, please try again'}
                text={
                  error?.details ||
                  'Please retry to resubmit again, or come back at a later time'
                }
                onClose={() => {
                  setError(undefined);
                }}
              />
            </Box>
          )}
          <Summary {...props} />
        </>
      ),
    },
  ];
  return {
    steps,
    handleClose,
    submit,
    openOrClose,
    setError,
  };
};

import { useState } from 'react';
import { Construction } from '@mui/icons-material';
import { Typography } from '@mui/material';
import DataTemplateDetails from 'components/data-template-details';
import DataDrawerGrid from '../../routes/parameters-page/common/grid';

function Tools() {
  const [openTools, setOpenTools] = useState(false);
  const handleOpen = () => setOpenTools(!openTools);

  // TO DO: Tools content

  return (
    <DataDrawerGrid
      icon={
        <Construction
          data-testid="tools-icon"
          sx={theme => ({
            color: theme.palette.primary.black,
          })}
        />
      }
      caption={
        <Typography
          variant="caption"
          color="textPrimary"
          data-testid="tools-label"
        >
          Tool used
        </Typography>
      }
      content={
        <DataTemplateDetails
          open={openTools}
          handleOpen={handleOpen}
          content="Tools content"
          subtitle="Tools"
        />
      }
    />
  );
}

Tools.propTypes = {};

export default Tools;
